import OutpatientPracticeDetails from "./OutpatientPracticeDetails";
import OutpatientPracticeDetails2 from "./OutpatientPracticeDetails2";
import ServiceToProviderMapping from "./ServiceProviderMapping/ServiceToProviderMapping";
import FrontDeskAssistantDetails from "./FrontDeskAssistantDetails";
import HealthInsuranceCarrier from "./HealthInsuranceCarrier";
import BrandIdentityAndMedia from "./BrandIdentityAndMedia";
import PaymentSetup from "./PaymentSetup";
import PatientsLandingPage from "./PatientsLandingPage";
import ProvidersLandingPage from "./ProvidersLandingPage";
import LogIn from "./LogIn";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ProviderInfo from "./Provider/ProviderInfo";
import InsuranceInfo from "./InsuranceInfo/InsuranceInfo";
import Clinicmedia from "./Clinicmedia";
import CreateClinic from "./Clinic/CreateClinic";
import CardDetails from "./CardDetails/CardDetails";
import AssistantInfo from "./AssistantInfo/AssistantInfo";
import Pricing from "./Pricing";
import BookAnAppointment from "./patients/BookAnAppointment";
import ChooseAppointment from "./patients/ChooseAppointment";
import PersonalAppointmentDetails from "./patients/PersonalAppointmentDetails";
import YourAppointmentBooked from "./patients/YourAppointmentBooked";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import BookingHistory from "./BookingHistory";
import ClinicBookingHistory from "./ClinicBookingHistory";
import ProviderCalender from "./ProviderCalender";
import ServiceProviderMappingProfile from "./ProviderMapping/ServiceProviderMapping";
import Dashboard from "./Dashboard";
import AssistProviderMapping from "./AssistanceMapping/AssistantProviderMapping";
import MySubscription from "./Accounts/mySubscription";
import Success from "./Success";
import Cancel from "./Cancel";
import { useSelector } from "react-redux";
import { selectProvider } from "../redux/actions/userSlice";
import ContactUs from "./ContactUs";
import ExplorePricing from "./ExplorePricing";
import ScheduleADemo from "./ScheduleADemo";
import Calendar from "./Calender";
import BookAnAppoinmentThirdParty from "./ThirdPartyBooking/BookAnAppoinmentThirdParty";
import AddBookingDetails from "./ThirdPartyBooking/AddBookingDetails";
import ThirdPartyPersonalAppoinmentDetails from "./ThirdPartyBooking/ThirdPartyPersonalAppoinmentDetails";
import ThirdPartAppoinmentBook from "./ThirdPartyBooking/ThirdPartAppoinmentBook";
import { initGA, logPageView } from "../ga";
import GetPricing from "./GetPricing";
import CommonModal from "./CommonModal";
import FAQ from "./FAQ";
import ThirdPartyReschedule from "./ThirdPartyBooking/ThirdPartyReschedule";
import PatientReschedule from "./patients/PatientReschedule";
import BookAppointmentDetails from "./ThirdPartyBooking/BookedAppointmentDetails";
import { RatingPage } from "./RatingPage";
import AssistanceProfile from "./AssistanceProfile";
import CliniBookAnAppoinment from "./AppointmentBookingClinic/ClinicBookAnAppointment";
import ClinicAddBookingDetails from "./AppointmentBookingClinic/ClinicAddBookingDetails";
import ClinicPersonalAppointmentDetails from "./AppointmentBookingClinic/ClinicPersonalAppointmentDetails";
import ClinicBookedAppointmentDetails from "./AppointmentBookingClinic/ClinicBookedAppointmentDetails";
import ClinicRescheduleAppointment from "./AppointmentBookingClinic/ClinicRescheduleAppointment";
import Services from "./Services";
import ReviewsAnalyticsPage from "./ReviewsAnalyticsPage";
import ProviderProfileSection from "./ProviderProfileSection";
import {Helmet, HelmetProvider} from 'react-helmet-async'

function App() {
  //const [isOpen, setIsOpen] = useState(false);
  const providerData = useSelector(selectProvider);
  const { pathname } = useLocation();
  // const [providerUser, setProviderUser] = useState();
  // useEffect(() => {
  //   setProviderUser(providerData.isLoggedIn);
  // }, [providerData]);
  const ProtectedRoute = ({ children }) => {
    const providerLog = JSON.parse(localStorage.getItem("providerLog"));
    const isLoggedIn = providerLog?.isLoggedIn;

    return isLoggedIn ? children : <Navigate to="/" replace />;
  };

  const location = useLocation();

   useEffect(() => {
    //  console.log("NODE_ENV:", process.env.NODE_ENV);
    //  console.log(
    //    "REACT_APP_GA_PRODUCTION_ID:",
    //    process.env.REACT_APP_GA_PRODUCTION_ID
    //  );
    //  console.log(
    //    "REACT_APP_GA_TESTING_ID:",
    //    process.env.REACT_APP_GA_TESTING_ID
    //  );

     const isProduction = process.env.NODE_ENV === "production";
     const measurementId = isProduction
       ? process.env.REACT_APP_GA_PRODUCTION_ID
       : process.env.REACT_APP_GA_TESTING_ID;

     if (measurementId) {
       initGA(measurementId);
     } else {
       console.error("Google Analytics Measurement ID is not defined");
     }
   }, []);

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

 

   const getPageMeta = (path) => {
     switch (path) {
       case "/login":
         return {
           title: "Log In - Samara Well",
           description: "Access your account at Samara Well.",
         };
       case "/forgotPassword":
         return {
           title: "Forgot Password - Samara Well",
           description: "Reset your Samara Well account password.",
         };
       case "/scheduleADemo":
         return {
           title: "Schedule a Demo - Samara Well",
           description: "Book a demo to explore our healthcare services.",
         };
       case "/pricing":
         return {
           title: "Pricing - Samara Well",
           description:
             "Explore the pricing plans for our services at Samara Well.",
         };
       // Add other routes here
       default:
         return {
           title: "Samara Well",
           description:
             "Your trusted healthcare partner providing top-notch medical services.",
         };
     }
   };

    const meta = getPageMeta(location.pathname);

  const providerSignupPaths = [
    "/pricing",
    "/paymentSetup",
    "/brandIdentity",
    "/healthInsuranceCarrier",
    "/frontDeskAssistant",
    "/serviceToProvider",
    "/outpatientPracticeDetails2",
    "/outpatientPractice",
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    const isProviderSignupPath = providerSignupPaths.some((path) =>
      pathname.includes(path)
    );
    if (!isProviderSignupPath) {
      localStorage.removeItem("organizationData");
      localStorage.removeItem("providerData");
      localStorage.removeItem("services");
      localStorage.removeItem("clinic_services_data");
      localStorage.removeItem("clinic_services_mapping");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("records");
      localStorage.removeItem("facilityImages");
      localStorage.removeItem("logoImage");
      localStorage.removeItem("highestCompletedStep_user123");
      // localStorage.removeItem("organizationId");
      // localStorage.removeItem("organizationEmail");
    }
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
      </Helmet>

    <Routes>
      <Route
        path="/login"
        element={
          <>
            <LogIn />
          </>
        }
      />
      <Route
        path="/forgotPassword"
        element={
          <>
            <ForgotPassword />
          </>
        }
      />
      <Route
        path="/changePassword"
        element={
          <>
            <ChangePassword />
          </>
        }
      />
      <Route
        path="/patientLanding"
        element={
          <>
            <PatientsLandingPage />
          </>
        }
      />
      <Route
        path="/"
        element={
          <>
            <ProvidersLandingPage />
          </>
        }
      />
      <Route
        path="/calender"
        element={
          <>
            <Calendar />
          </>
        }
      />
      <Route
        path="/scheduleADemo"
        element={
          <>
            <ScheduleADemo />
          </>
        }
      />
      <Route
        path="/explorePricing"
        element={
          <>
            <ExplorePricing />
          </>
        }
      />
      <Route
        path="/pricing"
        element={
          <>
            <Pricing />
          </>
        }
      />
      <Route
        path="/paymentSetup"
        element={
          <>
            <PaymentSetup />
          </>
        }
      />
      <Route
        path="/brandIdentity"
        element={
          <>
            <BrandIdentityAndMedia />
          </>
        }
      />
      <Route
        path="/healthInsuranceCarrier"
        element={
          <>
            <HealthInsuranceCarrier />
          </>
        }
      />
      <Route
        path="/frontDeskAssistant"
        element={
          <>
            <FrontDeskAssistantDetails />
          </>
        }
      />
      <Route
        path="/serviceToProvider"
        element={
          <>
            <ServiceToProviderMapping />
          </>
        }
      />
      <Route
        path="/outpatientPracticeDetails2"
        element={
          <>
            <OutpatientPracticeDetails2 />
          </>
        }
      />
      <Route
        path="/outpatientPractice"
        element={
          <>
            <OutpatientPracticeDetails />
          </>
        }
      />
      <Route
        path="/CreateClinic"
        element={
          <>
            <ProtectedRoute>
              <CreateClinic />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/providerInfo"
        element={
          <>
            <ProtectedRoute>
              <ProviderInfo />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/assistantInfo"
        element={
          <>
            <ProtectedRoute>
              <AssistantInfo />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/insuranceInfo"
        element={
          <>
            <ProtectedRoute>
              <InsuranceInfo />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/clinicmedia"
        element={
          <>
            <ProtectedRoute>
              <Clinicmedia />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/cliniccardDetails"
        element={
          <>
            <ProtectedRoute>
              <CardDetails />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/assistantProviderMapping"
        element={
          <>
            <ProtectedRoute>
              <AssistProviderMapping />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/AssistanceProfile"
        element={
          <>
            <ProtectedRoute>
              <AssistanceProfile />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/providerProfile"
        element={
          <>
            <ProtectedRoute>
              <ProviderProfileSection />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/mySubscription"
        element={
          <>
            <ProtectedRoute>
              <MySubscription />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/clinicBookingHistory/:tab?"
        element={
          <>
            <ProtectedRoute>
              <ClinicBookingHistory />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/paymentSuccess"
        element={
          <>
            <Success />
          </>
        }
      />
      <Route
        path="/paymentFail"
        element={
          <>
            <Cancel />
          </>
        }
      />
      <Route
        path="/serviceProviderMappingProfile"
        element={
          <>
            <ProtectedRoute>
              <ServiceProviderMappingProfile />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/providerCalender"
        element={
          <>
            <ProviderCalender />
          </>
        }
      />

      {/* <Route
        //path="/bookAnAppoinment"
        path="/bookAnAppoinment/:type/:name/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <BookAnAppointment />
          </>
        }
      /> */}
      <Route
        //path="/bookAnAppoinment"
        path="/bookAnAppoinment/:type/:name/"
        element={
          <>
            <BookAnAppointment />
          </>
        }
      />
      <Route
        //path="/bookAnAppoinment"
        path="/BookingHistory"
        element={
          <>
            <BookingHistory />
          </>
        }
      />
      {/* <Route
        path="/chooseAppoinment/:type/:name"
        element={
          <>
            <ChooseAppointment />
          </>
        }
      /> */}
      <Route
        path="/personalAppointmentDetails/:type/:name/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <PersonalAppointmentDetails />
          </>
        }
      />
      <Route
        path="/yourAppointmentBooked/:type/:name/:patientType/:insuranceType/:appointmentType/:viewId"
        element={
          <>
            <YourAppointmentBooked />
          </>
        }
      />
      <Route
        path="/patientreschedule/:viewId"
        element={
          <>
            <PatientReschedule />
          </>
        }
      />
      <Route
        path="/dashboard/:tabId?"
        element={
          <>
            <ProtectedRoute>
              {/* <Dashboard /> */}
              <ReviewsAnalyticsPage />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/contactus"
        element={
          <>
            <ContactUs />
          </>
        }
      />
      <Route
        path="/bookAnAppoinmentThirdParty/:type/:id"
        element={
          <>
            <BookAnAppoinmentThirdParty />
          </>
        }
      />
      <Route
        path="/bookAppoinment/:clinicName/:city?/:street?"
        element={
          <>
            <CliniBookAnAppoinment />
          </>
        }
      />
      <Route
        //path="/bookAnAppoinment"
        path="/addBookingDetails/:type/:id/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <AddBookingDetails />
          </>
        }
      />
      <Route
        //path="/bookAnAppoinment"
        path="/clinicaddbookingdetails/:clinicName/:locations/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <ClinicAddBookingDetails />
          </>
        }
      />
      <Route
        path="/thirdPartAppoinmentBook/:type/:id/:patientType/:insuranceType/:appointmentType/:viewId"
        element={
          <>
            <ThirdPartAppoinmentBook />
          </>
        }
      />
      <Route
        //path="/bookAnAppoinment"
        path="/thirdPartyPersonalAppoinmentDetails/:type/:id/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <ThirdPartyPersonalAppoinmentDetails />
          </>
        }
      />
      <Route
        //path="/bookAnAppoinment"
        path="/clinicpersonalappoinmentdetails/:clinicName/:locations/:patientType/:insuranceType/:appointmentType"
        element={
          <>
            <ClinicPersonalAppointmentDetails />
          </>
        }
      />
      <Route
        path="/thirdpartyreschedule/:viewId"
        element={
          <>
            <ThirdPartyReschedule />
          </>
        }
      />
      <Route
        path="/clinicrescheduleappointment/:viewId"
        element={
          <>
            <ClinicRescheduleAppointment />
          </>
        }
      />
      <Route
        path="/bookappointmentDetails/:viewId"
        element={
          <>
            <BookAppointmentDetails />
          </>
        }
      />
      <Route
        path="/clinicbookedappointmentDetails/:viewId"
        element={
          <>
            <ClinicBookedAppointmentDetails />
          </>
        }
      />
      <Route
        path="/getpricing"
        element={
          <>
            <GetPricing />
          </>
        }
      />
      <Route
        path="/commonmodel"
        element={
          <>
            <CommonModal />
          </>
        }
      />
      <Route
        path="/faq"
        element={
          <>
            <FAQ />
          </>
        }
      />

      <Route
        path="/RatingPage/:encryptedAppointmentId/:encryptedPatientId"
        element={<RatingPage />}
      />

      <Route
        path="/Services/:serviceId/:tab"
        element={
          <>
            <Services />
          </>
        }
      />
      <Route
        path="/reviewsanalytics/:tabId?"
        element={
          <>
            <ReviewsAnalyticsPage />
          </>
        }
      />
    </Routes>
    </HelmetProvider>
  );
}

export default App;
