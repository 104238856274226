// import { useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import { useEffect } from "react";
// // import { postData } from "../../Networking/Api";
// import { apiUrl, postData } from "../../Networking/Api";
// import Loader2 from "../Loader2";

// const shadowBottom = {
//   boxShadow: "0 6px 6px -6px #ccc",
// };

// function ClinicBookingHeader() {
//   const [location, setLocation] = useState({ lat: null, lon: null });
//   const [time, setTime] = useState(new Date().toLocaleTimeString());
//   const [locationName, setLocationName] = useState("");
//   const [clinicNameData, setClinicNameData] = useState("");
//   const [phone, setPhone] = useState("");
//   const [streetAdd, setStreetAdd] = useState("");
//   const [address, setAddress] = useState("");
//   const [logoImg, setLogoImg] = useState("");
//   const [loading, setLoading] = useState(true);

//   let { clinicName, city, street } = useParams();

//   useEffect(() => {
//     const fetchOptions = async () => {
//       try {
//         setLoading(true);
//         let payload;

//         if (!city || !street) {
//           payload = {
//             clinicName: clinicName,
//           };
//         } else {
//           payload = {
//             clinicName: clinicName,
//             cityStreetName: `${city}-${street}`,
//           };
//         }

//         const response = await postData("clinics/getClinicInfo", payload);
//         const data = response;
//         // console.log("data", data);
//         setLogoImg(data.clinics.logo_image);
//         setClinicNameData(data.clinics.name);
//         setStreetAdd(data.clinic_locations_data.street);
//         setPhone(data.clinic_locations_data.phone);
//         const {
//           city: locationCity,
//           state,
//           country,
//           zipcode,
//         } = data.clinic_locations_data;
//         const fullAddress = `${locationCity}, ${state}, ${country}, ${zipcode}`;
//         setAddress(fullAddress);
//       } catch (error) {
//         // console.log("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOptions();
//   }, [clinicName, city, street]);

//   useEffect(() => {
//     getLocationFromIP();

//     const timer = setInterval(() => {
//       setTime(new Date().toLocaleTimeString());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

//   const getLocationFromIP = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch("https://ipapi.co/json/");
//       const data = await response.json();
//       const newLocation = {
//         lat: data.latitude,
//         lon: data.longitude,
//       };
//       setLocation(newLocation);
//       localStorage.setItem("location", JSON.stringify(newLocation));
//     } catch (error) {
//       console.error("Error fetching IP location:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       {loading && <Loader2 />}
//       <header className="w-full">
//         <div
//           className="pl-8 flex justify-between items-center px-4 bg-white shadow-sm"
//           style={shadowBottom}
//         >
//           <div className="flex items-center gap-3"></div>
//           <div className="flex-grow flex justify-center">
//             <Link to="/">
//               <img
//                 width={180}
//                 height={130}
//                 src={logoImg && `${apiUrl}/${logoImg}`}
//                 alt="logo"
//                 className="p-2"
//               />
//             </Link>
//           </div>

//           <div className="p-4">
//             <div className="flex items-start space-x-2 mb-2">
//               <div className="flex items-start justify-center">
//                 <span>
//                   <img src="/loc.png" width="44px" alt="Location Icon" />
//                 </span>
//               </div>
//               <div>
//                 <span className="text-xs text-gray-500">LOCATION</span>
//                 <div className="text-lg font-semibold text-gray-900">
//                   {clinicNameData}
//                 </div>
//                 <div className="text-sm text-gray-700">
//                   <p>{streetAdd}</p>

//                   <p>{address && address}</p>

//                   <p className="text-blue-600 cursor-pointer hover:underline">
//                     <a href={`tel:${phone}`}>{phone}</a>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//     </>
//   );
// }

// export default ClinicBookingHeader;
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { apiUrl, postData } from "../../Networking/Api";
import Loader2 from "../Loader2";

const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

function ClinicBookingHeader(props) {
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [clinicNameData, setClinicNameData] = useState("");
  const [phone, setPhone] = useState("");
  const [streetAdd, setStreetAdd] = useState("");
  const [address, setAddress] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [loading, setLoading] = useState(true);

  let { clinicName, city, street } = useParams();

  if (!street && city && city.includes("-")) {
    [city, street] = city.split("-");
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let payload;
        setLoading(true);
        if (props.clinicNameParam == "") {
          console.log("if part---");
          if (!city || !street) {
            payload = {
              clinicName: clinicName,
            };
          } else {
            payload = {
              clinicName: clinicName,
              cityStreetName: `${city}-${street}`,
            };
          }
        } else {
          console.log("else part---");
          console.log(
            "props.cityStreetNameParam---",
            props.cityStreetNameParam
          );
          console.log("props.clinicNameParam---", props.clinicNameParam);
          payload = {
            clinicName: props.clinicNameParam,
            cityStreetName: props.cityStreetNameParam,
          };
        }

        const response = await postData("clinics/getClinicInfo", payload);
        const data = response;
        // console.log("data", data);
        setLogoImg(data.clinics.logo_image);
        setClinicNameData(data.clinics.name);
        setStreetAdd(data.clinic_locations_data.street);
        setPhone(data.clinic_locations_data.phone);
        const {
          city: locationCity,
          state,
          country,
          zipcode,
        } = data.clinic_locations_data;
        const fullAddress = `${locationCity}, ${state}, ${country}, ${zipcode}`;
        setAddress(fullAddress);
      } catch (error) {
        // console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [
    clinicName,
    city,
    street,
    props.clinicNameParam,
    props.cityStreetNameParam,
  ]);

  useEffect(() => {
    getLocationFromIP();

    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getLocationFromIP = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      const newLocation = {
        lat: data.latitude,
        lon: data.longitude,
      };
      setLocation(newLocation);
      localStorage.setItem("location", JSON.stringify(newLocation));
    } catch (error) {
      console.error("Error fetching IP location:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      {/* <header className="w-full">
        <div
          className="pl-8 flex justify-between items-center px-4 bg-white shadow-sm"
          style={shadowBottom}
        >
          <div className="flex items-center gap-3"></div>
          <div className="flex-grow flex justify-center">
            <Link to="/">
              <img
                width={180}
                height={130}
                src={logoImg && `${apiUrl}/${logoImg}`}
                alt="logo"
                className="p-2"
              />
            </Link>
          </div>

          <div className="p-4">
            <div className="flex items-start space-x-2 mb-2">
              <div className="flex items-start justify-center">
                <span>
                  <img src="/loc.png" width="44px" alt="Location Icon" />
                </span>
              </div>
              <div>
                <span className="text-xs text-gray-500">LOCATION</span>
                <div className="text-lg font-semibold text-gray-900">
                  {clinicNameData}
                </div>
                <div className="text-sm text-gray-700">
                  <p>{streetAdd}</p>

                  <p>{address && address}</p>

                  <p className="text-blue-600 cursor-pointer hover:underline">
                    <a href={`tel:${phone}`}>{phone}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header> */}
      <header className="w-full relative">
        <div
          className="pl-8 pb-6 pt-6 flex items-center px-4 bg-white shadow-sm"
          style={shadowBottom}
        >
          <div className="lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:pl-0 pl-4">
            <Link to="/">
              <img
                width={180}
                height={130}
                src={logoImg && `${apiUrl}/${logoImg}`}
                alt="logo"
                className="p-2"
              />
            </Link>
          </div>
          <div className="ml-auto p-4">
            <div className="flex items-start space-x-2 mb-2">
              <div className="flex items-start justify-center">
                <span>
                  <img src="/loc.png" width="44px" alt="Location Icon" />
                </span>
              </div>
              <div>
                <span className="text-xs text-gray-500">LOCATION</span>
                <div className="text-lg font-semibold text-gray-900">
                  {clinicNameData}
                </div>
                <div className="text-sm text-gray-700">
                  <p>{streetAdd}</p>
                  <p>{address && address}</p>
                  <p className="text-blue-600 cursor-pointer hover:underline">
                    <a href={`tel:${phone}`}>{phone}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default ClinicBookingHeader;
