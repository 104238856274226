import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { encrypt } from "./CryptoUtil";
import Loader2 from "./Loader2";
import moment from "moment";

const BookingHistory = () => {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const bookingsPerPage = 10;
  const [patientId, setPatientId] = useState("");
  const [selectedTab, setSelectedTab] = useState("All Bookings");
  const [appointmentTypes, setAppointmentTypes] = useState({});
  const [insuranceName, setInsuranceName] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem("userCreateInfo");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        const patientIds = parsedData.map((patient) => patient.id);

        if (patientIds.length > 0) {
          setPatientId(patientIds[0]);
        } else {
          console.error("No patient IDs found in stored data.");
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
      }
    } else {
      console.error("No stored data found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        if (!patientId) {
          console.error("Patient ID is not available");
          return;
        }

        const payload = {
          patient_id: patientId,
        };
        const response = await postData(
          "patients/getPatientsBookingHistory",
          payload
        );
        setLoading(false);
        if (response.success) {
          const updatedBookings = response.data.map((booking) =>
            booking.status === "Pending"
              ? { ...booking, status: "Booked" }
              : booking
          );
          setBookings(updatedBookings);
        } else {
          console.error("Failed to fetch bookings");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [patientId]);

  useEffect(() => {
    const fetchAppointmentTypes = async () => {
      try {
        const appointmentsWithServiceIds = bookings.filter(
          (booking) => booking.clinic_service_id !== null
        );

        const promises = appointmentsWithServiceIds.map((booking) => {
          return fetchAppointmentType(booking.clinic_service_id);
        });

        const results = await Promise.all(promises);

        const updatedAppointmentTypes = results.reduce(
          (acc, appointmentType) => {
            if (appointmentType) {
              acc[appointmentType.id] = appointmentType;
            }
            return acc;
          },
          {}
        );

        setAppointmentTypes(updatedAppointmentTypes);
      } catch (error) {
        console.error("Error fetching appointment types:", error);
      }
    };

    fetchAppointmentTypes();
  }, [bookings]);

  const fetchAppointmentType = async (clinicServiceId) => {
    try {
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          id: clinicServiceId,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      if (response.status === 200 && response.data.length > 0) {
        return response.data[0];
      } else {
        console.error("No appointment type found for ID:", clinicServiceId);
        return null;
      }
    } catch (error) {
      console.error("Error fetching appointment type:", error);
      return null;
    }
  };

  useEffect(() => {
    // Fetch insurance data for bookings with null insurance and valid clinic_insurance_id
    const fetchInsuranceData = async (booking) => {
      try {
        if (!booking.insurance && booking.clinic_insurance_id) {
          const payload = {
            modelName: "clinic_insurances",
            whereCondition: {
              id: booking.clinic_insurance_id,
            },
            relations: [
              {
                module: "insurance_masters",
              },
              {
                module: "health_insurance_carrier_master",
              },
            ],
          };

          const response = await postData("masters/getMasterList", payload);

          if (response.status === 200 && response.data.length > 0) {
            // Assuming response.data[0] contains the relevant insurance data
            setInsuranceName((prevInsuranceName) => ({
              ...prevInsuranceName,
              [booking.id]: {
                healthInsuranceCarrierName:
                  response.data[0].health_insurance_carrier_master
                    .health_insurance_carrier_name,
                insuranceName:
                  response.data[0].insurance_masters.insurance_name,
              },
            }));
          } else {
            console.error(
              "No insurance data found for clinic_insurance_id:",
              booking.clinic_insurance_id
            );
          }
        }
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };

    // Fetch insurance data for each booking that requires it
    const fetchInsuranceTypes = async () => {
      try {
        const filteredBookings = bookings.filter(
          (booking) => booking.insurance === null && booking.clinic_insurance_id
        );

        filteredBookings.forEach((booking) => {
          fetchInsuranceData(booking);
        });
      } catch (error) {
        console.error("Error fetching insurance types:", error);
      }
    };

    fetchInsuranceTypes();
  }, [bookings]);

  const filteredBookings = bookings.filter((booking) => {
    switch (selectedTab) {
      case "Booked Bookings":
        return booking.status === "Booked";
      case "Confirmed Bookings":
        return booking.status === "Confirmed";
      case "Cancelled Bookings":
        return booking.status === "Cancelled";
      case "Discharged Bookings":
        return booking.status === "Discharged";
      default:
        return true;
    }
  });

  const totalPages = Math.ceil(filteredBookings.length / bookingsPerPage);
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = filteredBookings.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCancel = async (bookingId) => {
    try {
      const result = await Swal.fire({
        title: "Cancel Appointment",
        icon: "warning",
        html: `
        <p>Are You Sure You Want To Cancel This Appointment?</p>
        <p>You Won't Be Able To Revert This!</p>
        <input id="cancelReason" class="swal2-input" placeholder="Enter reason for cancelling">
      `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel It!",
        preConfirm: () => {
          const reason = document.getElementById("cancelReason").value;
          if (!reason) {
            Swal.showValidationMessage("Reason Is Required");
            return false;
          }
          return reason;
        },
      });
      setLoading(true);
      if (result.isConfirmed) {
        const payload = {
          inputData: {
            id: bookingId,
            user_type: localStorage.getItem("accountType"),
            user_type_id: patientId,
            reasonForCancel: result.value,
          },
        };

        const response = await postData("common/cancel", payload);
        setLoading(false);
        if (response.success) {
          Swal.fire({
            title: "Cancelled!",
            text: "Your Appointment Has Been Cancelled.",
            icon: "success",
            timer: 2000,
          });

          setBookings((prevBookings) =>
            prevBookings.map((booking) =>
              booking.id === bookingId
                ? { ...booking, status: "Cancelled" }
                : booking
            )
          );
        } else {
          console.error(
            "Failed to cancel booking:",
            response.error || response
          );
          Swal.fire({
            title: "Failed To Cancel",
            text: "Failed To Cancel The Appointment.",
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error cancelling booking:", error);
      Swal.fire({
        title: "Error",
        text: "Failed To Cancel The Appointment. Please Try Again Later.",
        icon: "error",
        timer: 2000,
      });
    }
  };

  const handleNavigate = (appointmentId, isSubmittedRating) => {
    if (isSubmittedRating === null || isSubmittedRating === 0) {
      //const encryptedAppointmentId = encrypt(appointmentId.toString());
      //const encryptedPatientId = encrypt(patientId.toString());
      // navigate(
      //   `/RatingPage/${encodeURIComponent(
      //     encryptedAppointmentId
      //   )}/${encodeURIComponent(encryptedPatientId)}`
      // );
       navigate(
         `/RatingPage/${appointmentId}/${patientId}`
       );
    }
  };

  const formatDate = (dateTimeString) => {
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz("America/New_York")
      .format("MM/DD/YYYY");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "UTC",
    };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString("en-US", options);
  };

  const formatDateTime = (dateTimeString) => {
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz("America/New_York")
      .format("HH:mm A");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    };
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString(undefined, options);
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div className="w-full mt-3">
            <section className="w-full">
              <div className="container mx-auto flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                <h1 className="text-2xl font-semibold mb-4 sm:mb-0 text-center sm:text-left sm:pl-8 mt-2 md:w-full md:mb-4">
                  Booking Details
                </h1>
                <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                  {[
                    "All Bookings",
                    "Booked Bookings",
                    "Confirmed Bookings",
                    "Discharged Bookings",
                    "Cancelled Bookings",
                  ].map((tab) => (
                    <li key={tab} className="me-2">
                      <button
                        onClick={() => setSelectedTab(tab)}
                        className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                          selectedTab === tab
                            ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                            : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                        }`}
                      >
                        {tab.split(" ")[0]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
            <section className="w-full p-3">
              <div className="container mx-auto mt-2 px-4 py-6 rounded-lg shadow-lg bg-white">
                {currentBookings.length > 0 ? (
                  currentBookings.map((booking, index) => {
                    const appointmentType =
                      appointmentTypes[booking.clinic_service_id];
                    return (
                      <div
                        key={index}
                        className="w-full max-w-6xl mx-auto bg-gray-200 p-4 mb-8 mt-8 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl big-height flex flex-col align-items-start"
                      >
                        <div className="absolute top-2 right-4">
                          {booking.status !== "Cancelled" &&
                            booking.status !== "Discharged" && (
                              <button
                                className="bg-red-500 text-white py-2 px-2 rounded mr-2"
                                onClick={() => handleCancel(booking?.id)}
                              >
                                Cancel
                              </button>
                            )}

                          {booking.status === "Discharged" &&
                            (booking.is_submitted_ratting !== 1 ||
                              booking.is_submitted_ratting == null) && (
                              <button
                                className="bg-blue-500 text-white py-2 px-2 rounded mr-2"
                                onClick={() =>
                                  handleNavigate(
                                    booking?.id,
                                    booking?.is_submitted_ratting
                                  )
                                }
                              >
                                Rating
                              </button>
                            )}
                        </div>
                        <h2 className="text-xl font-semibold mb-2 flex justify-between px-4 pl-8">
                          {booking?.clinics?.name}{" "}
                        </h2>
                        <div className="flex justify-between px-4 pl-8">
                          <div className="w-1/2">
                            <p className="text-gray-700">
                              <strong>Practitioner:</strong>{" "}
                              {booking?.practitioners?.f_name}{" "}
                              {booking?.practitioners?.l_name}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Date:</strong>{" "}
                              {formatDate(booking?.appointment_date_time)}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Time:</strong>{" "}
                              {formatDateTime(booking.appointment_date_time)}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Duration:</strong>{" "}
                              {booking?.duration || "N/A"}
                            </p>
                          </div>
                          <div className="w-1/2 pl-8">
                            <p className="text-gray-700">
                              <strong>Status:</strong> {booking?.status}
                            </p>
                            <p className="text-gray-700">
                              {booking.insurance ? (
                                <p className="text-gray-700">
                                  <strong>Insurance Type:</strong>{" "}
                                  {booking.insurance}
                                </p>
                              ) : insuranceName[booking.id] ? (
                                <>
                                  <p className="text-gray-700">
                                    <strong>Insurance Carrier Name:</strong>{" "}
                                    {
                                      insuranceName[booking.id]
                                        .healthInsuranceCarrierName
                                    }
                                  </p>
                                  <p className="text-gray-700">
                                    <strong>Insurance Name:</strong>{" "}
                                    {insuranceName[booking.id].insuranceName}
                                  </p>
                                </>
                              ) : (
                                <p className="text-gray-700">
                                  <strong>Insurance Type:</strong> N/A
                                </p>
                              )}
                            </p>

                            <p className="text-gray-700">
                              <strong>Appointment Type:</strong>{" "}
                              {appointmentType
                                ? appointmentType.services
                                : "N/A"}
                            </p>
                            <p className="text-gray-700">
                              <strong>Reason:</strong>{" "}
                              {booking?.reason || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center width:800px h-96 bg-white-200 p-8 rounded-lg shadow-lg border border-gray-300">
                      <div className="h-20 w-20 rounded-full bg-blue-500 flex items-center justify-center mb-4">
                        <svg
                          className="h-16 w-16 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </div>
                      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                        No Appointments
                      </h2>
                      <p className="text-lg text-gray-700 text-center">
                        You Don't Have Any Upcoming Appointments.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
        <div className="absolute bottom-4 right-4">
          <nav>
            <ul className="flex">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className="mx-1">
                  <button
                    className={`px-3 py-1 rounded-full ${
                      currentPage === index + 1
                        ? "bg-gray-800 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <Footer />
      </>
    </>
  );
};

export default BookingHistory;
