import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    const dataType = this.detectDataType(props.data);
    this.state = {
      series: [
        {
          name: "Appointments",
          data: this.getSeriesData(props.data, dataType),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
        },
        xaxis: {
          categories: this.getCategories(props.data, dataType),
        },
        yaxis: {
          title: {
            text: "Number of Appointments",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const dataType = this.detectDataType(this.props.data);
      this.setState({
        series: [
          {
            name: "Appointments",
            data: this.getSeriesData(this.props.data, dataType),
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.getCategories(this.props.data, dataType),
          },
        },
      });
    }
  }

  detectDataType(data) {
    if (data.length === 0) return "unknown";
    if (data[0].day) return "daily";
    if (data[0].week) return "weekly";
    if (data[0].month) return "monthly";
    if (data[0].year) return "yearly";
    return "unknown";
  }

  getSeriesData(data, dataType) {
    return data.map((item) => parseInt(item.count, 10));
  }

  getCategories(data, dataType) {
    if (dataType === "daily") {
      return data.map((item) =>
        new Date(item.day).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
        })
      );
    } else if (dataType === "weekly") {
      return data.map((_, index) => `Week ${index + 1}`);
    } else if (dataType === "monthly") {
      return data.map((item) =>
        new Date(item.month).toLocaleDateString("en-US", { month: "short" })
      );
    } else if (dataType === "yearly") {
      return data.map((item) => item.year.split("-")[0]); // Directly use item.year
    }
    return [];
  }

  render() {
    return (
      <div>
        <div>Scheduling Growth</div>
        <div id="chart" className="relative">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default LineChart;
