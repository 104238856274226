import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postData, apiUrl } from "../Networking/Api";

function ProvidersLandingPage() {
  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
        <PatientsLandingPageHeader />
      </div>
      <div className="pt-16">
        <VideoBanner />
        <DelightYourPatients />
        <SetsYourPracticeGrowth />
        <ChooseTheRightPlan />
        <GetUnlimitedAccessOurAi />
        <WhatPatientsSay />
        <ProviderLandingPageFooter />
      </div>
    </>
  );
}

function VideoBanner() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  const navigate = useNavigate();

  const handleNavigation = (serviceId, tab) => {
    navigate(`/Services/${serviceId}/${tab}`);
  };

  return (
    <section className="w-full">
      <div className="w-full relative">
        <div className="w-full z-20 absolute left-1/2 transform -translate-x-1/2 top-1/2 transform -translate-y-1/2 text-center">
          <h1 className="font-fontPoppins xss:text-xl sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-bold leading-relaxed">
            Your Outpatient Care
          </h1>
          <h1 className=" pt-2 font-fontPoppins xss:text-xl sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-bold leading-relaxed">
            Co-Pilot
          </h1>

          <div className="w-full arrow-btn-box mx-auto xss:flex xss:justify-center xss:items-center sm:flex sm:justify-center sm:items-center md:flex md:justify-center md:items-center md:mt-6">
            <div
              className="btn-arrow mx-auto bg-scroll bg-no-repeat bg-cover text-center content-center font-fontPoppins text-xs font-semibold cursor-pointer"
              style={{ backgroundImage: "url(arrow-image.png)" }}
              onClick={() => handleNavigation(1, "Scheduling")}
            >
              Scheduling
            </div>
            <div
              className="btn-arrow mx-auto bg-scroll bg-no-repeat bg-cover text-center content-center font-fontPoppins text-xs font-semibold cursor-pointer"
              style={{ backgroundImage: "url(arrow-image.png)" }}
              onClick={() => handleNavigation(2, "Reivew & Outreach")}
            >
              Reviews & Outreach
            </div>

            <div
              className="btn-arrow mx-auto bg-scroll bg-no-repeat bg-cover text-center content-center font-fontPoppins text-xs font-semibold cursor-pointer"
              style={{ backgroundImage: "url(arrow-image.png)" }}
              onClick={() => handleNavigation(3, "Insights")}
            >
              Insights
            </div>

            <div
              className="btn-arrow mx-auto bg-scroll bg-no-repeat bg-cover text-center content-center font-fontPoppins text-xs font-semibold cursor-pointer"
              style={{ backgroundImage: "url(arrow-image.png)" }}
              onClick={() => handleNavigation(4, "Future-Roadmap")}
            >
              Future Roadmap
            </div>
          </div>

          <div className="w-full mt-4 flex items-center justify-center">
            <button
              onClick={() => navigate("/scheduleADemo")}
              className="px-5 py-2 font-fontPoppins text-xs font-semibold bg-white rounded-full"
            >
              See Demo <i className="fa-solid fa-circle-play"></i>
            </button>
          </div>
        </div>
        <div className="bg-opacity-30 z-10 absolute inset-0 left-0 top-0"></div>
        <video
          ref={videoRef}
          // className="w-full max-h-120 object-contain"
          className="w-full xss:h-80 sm:h-80 md:h-96 lg:h-h44 object-cover"
          src="/vid.mp4"
          autoPlay
          muted
          loop
          playsInline
          // frameborder={"0"}
        />
      </div>
    </section>
  );
}

function DelightYourPatients() {
  return (
    <section className="w-full">
      <div className="container mx-auto px-4 py-12">
        <h1 className="font-fontPoppins text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
          Improve Your Practice Outcomes
        </h1>

        <div className="w-full mt-4 md:flex md:justify-between md:gap-4">
          <div className="w-full xss:mt-3 sm:mt-3 grid grid-cols-1 gap-4">
            <div className="w-full h-full bg-gray-200 p-5 rounded-lg text-right content-center">
              <h5 className="font-fontPoppins text-sm font-bold">
                1-Touch Scheduling
              </h5>
              <p className="font-fontPoppins text-xs">
                1-touch Search to Appointment in under 1-minute for your new and
                existing patients with full EHR Interoperability.
              </p>
            </div>

            <div className="w-full h-full bg-gray-200 p-5 rounded-lg text-right content-center place-self-end">
              <h5 className="font-fontPoppins text-sm font-bold">
                Improve Patient Outcomes
              </h5>
              <p className="font-fontPoppins text-xs">
                Fast and Easy Patient Access with improved Patient Healthcare
                Adherence
              </p>
            </div>
          </div>

          <div
            className="md:w-3/6 h-80 xss:mt-3 sm:mt-3 rounded-3xl bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: "url(./sonography-img.jpg)" }}
          ></div>

          <div className="w-full xss:mt-3 sm:mt-3 grid grid-cols-1 gap-4">
            <div className="w-full h-full bg-gray-200 p-5 rounded-lg text-left content-center">
              <h5 className="font-fontPoppins text-sm font-bold">
                Front-Office Co-Pilot
              </h5>
              <p className="font-fontPoppins text-xs">
                Delight your Front-Office by saving them time and empowering
                them with tools for better customer care.
              </p>
            </div>

            <div className="w-full h-full bg-gray-200 p-5 rounded-lg text-left content-center place-self-end">
              <h5 className="font-fontPoppins text-sm font-bold">
                Grow Your Practice
              </h5>
              <p className="font-fontPoppins text-xs">
                Patient & revenue growth. Happy patients, happy providers,
                5-Star reviews and saves you time everywhere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SetsYourPracticeGrowth() {
  return (
    <section className="w-full">
      <div className="container mx-auto px-4">
        <div
          className="w-full min-h-mh480 xss:min-h-80 sm:min-h-72 rounded-3xl bg-no-repeat bg-cover bg-top relative overflow-hidden"
          style={{ backgroundImage: "url(./lady-doctor.jpg)" }}
        >
          <div className="w-full h-full z-20 absolute inset-0 bg-black bg-opacity-40 content-center sm:flex sm:justify-center sm:items-center sm:gap-4 md:flex md:justify-center md:items-center md:gap-4">
            <h3 className="font-fontPoppins text-white text-right xss:text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
              Sets Your Practice Growth <br />
              on <span className="text-white">Auto-Pilot</span>
            </h3>
            <a
              href={"/scheduleADemo"}
              className="w-40 h-14 xss:mx-auto xss:w-28 xss:h-10 sm:w-28 sm:h-10 bg-no-repeat bg-contain flex items-center justify-center font-fontPoppins text-sm xss:text-xs sm:text-xs font-semibold"
              style={{
                backgroundImage: "url(./learn-more-btn.png)",
              }}
            >
              See Demo <i className="fa-solid fa-circle-play ml-1"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

function ChooseTheRightPlan() {
  const navigate = useNavigate();
  return (
    <section className="w-full">
      <div className="container mx-auto px-4 py-12">
        <div className="md:w-2/5 mx-auto bg-gray-200 px-4 py-6 rounded-lg text-center">
          <h4 className="font-fontPoppins text-center text-2xl font-bold xss:text-lg sm:text-lg leading-tight">
            Choose the <span className="text-blueColor">right plan</span> for{" "}
            <br />
            your practice
          </h4>

          <div className="w-full mt-4 content-center">
            <button
              onClick={() => navigate("/getpricing")}
              className="w-36 py-3 border-none font-fontPoppins text-xs font-normal bg-blueColor rounded-full text-white"
            >
              Explore Pricing <i className="fa-solid fa-arrow-right-long"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

function GetUnlimitedAccessOurAi() {
  const navigate = useNavigate();
  return (
    <section className="w-full">
      <div className="container mx-auto px-4">
        <div
          className="w-full min-h-mh480 xss:min-h-80 sm:min-h-72 rounded-3xl bg-no-repeat bg-cover bg-top relative overflow-hidden"
          style={{ backgroundImage: "url(./ai.jpg)" }}
        >
          <div className="w-full h-full z-20 absolute inset-0 bg-black bg-opacity-40 content-center ">
            <h5 className="font-fontPoppins text-white text-center xss:text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
              {/* Get unlimited access to our AI-powered <br />
              features when you become a customer. */}
              See how Samara Well can transform your Outpatient Practice
            </h5>

            <div className="w-full mt-4 content-center mx-auto text-center">
              <button
                onClick={() => navigate("/getpricing")}
                className="w-36 py-3 border-none font-fontPoppins text-xs font-normal bg-blueColor rounded-full text-white"
              >
                Explore Pricing <i className="fa-solid fa-arrow-right-long"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function WhatPatientsSay() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    // Define the payload with the required conditions
    const payload = {
      modelName: "blogs",
      whereCondition: {
        is_active: 1,
        type: "provider",
      },
    };

    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await await postData("masters/getMasterList", payload);
        // console.log('list==============>',response.data);
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchData();
  }, []);

  return (
    // <section className="w-full">
    //   <div className="container mx-auto py-8 px-4">
    //   <h6 className="w-full text-center font-fontPoppins xss:text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
    //       What our <span className="text-blueColor">Providers</span> are saying
    //     </h6>
    //     <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    //       {testimonials.map((testimonial, index) => (
    //         <div className="w-full xss:mt-3 shadow-md bg-gray-100 p-1 rounded-md" key={index}>
    //           <div className="text-center">
    //             <i className="fa-solid fa-quote-left fa-4x text-gray-400"></i>
    //           </div>

    //           <p
    //             className="font-fontPoppins mb-2 text-sm text-center font-medium"
    //             dangerouslySetInnerHTML={{ __html: testimonial.description }}>
    //             {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.  */}
    //           </p>
    //             <img
    //             src={`${apiUrl}/${testimonial.image}`}

    //             className="rounded-full h-14 w-14 shadow-lg mx-auto"
    //             alt="Profile Pic"
    //           />
    //           <p className="font-fontPoppins text-xs mt-1 font-medium text-center">
    //             {testimonial.title} {/* Adjust based on actual API response structure */}
    //           </p>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </section>
    <section className="w-full">
      <div className="container mx-auto py-8 px-4">
        <h6 className="w-full text-center font-fontPoppins xss:text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
          What our <span className="text-blueColor">Providers</span> are saying
        </h6>
        <div
          className={`mt-4 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3`}
        >
          {testimonials.map((testimonial, index) => (
            <div
              className={`w-full xss:mt-3 shadow-md bg-gray-100 p-4 rounded-md ${
                testimonials.length === 4 && index === 3 ? "md:col-start-2" : ""
              }`}
              key={index}
            >
              <div className="text-center">
                <i className="fa-solid fa-quote-left fa-2x text-gray-400"></i>
              </div>
              <p
                className="font-fontPoppins mb-4 text-sm text-center font-medium"
                dangerouslySetInnerHTML={{ __html: testimonial.description }}
              ></p>
              <img
                src={`${apiUrl}/${testimonial.image}`}
                className="rounded-full h-24 w-24 shadow-lg mx-auto mb-2 object-cover"
                alt="Profile Pic"
              />
              <p className="font-fontPoppins text-xs mt-1 font-medium text-center">
                {testimonial.title}{" "}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProvidersLandingPage;
