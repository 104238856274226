import React, { useEffect, useState } from "react";
import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import { postData, apiUrl } from "../Networking/Api";
import { useParams } from "react-router-dom";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";

const Services = () => {
  const { serviceId, tab } = useParams();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        modelName: "services_details",
        service_id: serviceId,
        whereCondition: {
          is_active: 1,
        },
      };
      try {
        const response = await postData("masters/getMasterList", payload);
        console.log("API response:", response);

        if (response.status === 200 && response.data) {
          const serviceIdNumber = parseInt(serviceId, 10);

          const filteredSections = response.data.filter(
            (section) => section.service_id === serviceIdNumber
          );

          const sortedSections = filteredSections.sort(
            (a, b) => a.sequences - b.sequences
          );
          setSections(sortedSections);
        } else {
          throw new Error(response.message || "Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [serviceId]);

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
        <PatientsLandingPageHeader />
      </div>
      <div className="pt-20 px-4 md:px-8 lg:px-16">
        <div className="text-center">
          <h2 className="text-black font-bold text-4xl">
            {tab ? tab.charAt(0).toUpperCase() + tab.slice(1) : "Default Title"}
          </h2>
        </div>

        {sections.map((section, index) => (
          <section
            key={section.id}
            className="py-10 m-6 flex flex-wrap md:flex-nowrap"
          >
            {index % 2 === 0 ? (
              <>
                <div
                  className="w-full md:w-2/5 p-4 md:mr-8"
                  style={{
                    backgroundImage: section.image
                      ? `url(${apiUrl}${section.image})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "50vh",
                  }}
                ></div>
                <div className="w-full md:w-3/5 m-8 p-8 relative">
                  <span className="absolute top-0 left-8 text-xl font-semibold">
                    {String(index + 1).padStart(2, "0")}
                  </span>
                  <h2
                    className="text-4xl mt-4 font-normal mb-4"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {section.title}
                  </h2>
                  <div
                    className="text-xl  mb-8 text-left"
                    style={{
                      fontFamily: "sans-serif",
                    }}
                    dangerouslySetInnerHTML={{ __html: section.description }}
                  ></div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full md:w-3/5 m-8 p-8 relative ">
                  <span className="absolute top-0 right-4 mr-2 text-xl font-semibold">
                    {String(index + 1).padStart(2, "0")}
                  </span>
                  <h2
                    className="text-4xl mt-4 font-normal mb-4 text-left"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {section.title}
                  </h2>
                  <div
                    className="text-xl mb-8  text-left"
                    style={{ fontFamily: "sans-serif" }}
                    dangerouslySetInnerHTML={{ __html: section.description }}
                  ></div>
                </div>
                <div
                  className="w-full md:w-2/5 p-4"
                  style={{
                    backgroundImage: section.image
                      ? `url(${apiUrl}${section.image})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "50vh",
                  }}
                ></div>
              </>
            )}
          </section>
        ))}
      </div>

      <ProviderLandingPageFooter />
    </>
  );
};

export default Services;
