import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useState, useEffect, useRef } from "react";
import { apiUrl, postData } from "../Networking/Api";
import StepsForRegistration from "./StepsForRegistration";
import CustomSelect from "./CustomSelect";
import axios from "axios";
import CustomSelectHealth from "./CustomSelectHealth";
import Swal from "sweetalert2";
import { Switch } from "@headlessui/react";
import Loader from "./Loader";
import Loader2 from "./Loader2";

function OutpatientPracticeDetails2() {
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [provaddress, setProvAddress] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showProviderSection, setShowProviderSection] = useState(false);
  const [providerisChecked, setProviderisChecked] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    name: "",
    email: "",
    website: "",
    // phone: "",
    // faxNumber: "",
    npi_number: "",
    // street: "",
    // city: "",
    // state: "",
    // address_ype: "",
    // zipcode: "",
    taxonomy_description: "",
    // country: "",
    is_active: 1,
    isChecked: false,
    latitude: "",
    longitude: "",
    NPI_details: "",
    logo_image: "",
  });
  const [locations, setLocations] = useState({
    phone: "",
    fax_number: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    address_ype: "",
    is_active: 1,
    is_deleted: 0,
  });
  const [addLocations, setAddLocations] = useState([]);
  const [locationError, setLocationError] = useState({});
  const [orgNpiData, setOrgNpiData] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [enlargedImageUrl, setEnlargedImageUrl] = useState("");
  const [providerErrors, setProviderErrors] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const [providerData, setProviderData] = useState([]);
  const [provNpiData, setProvNpiData] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [newProviderData, setNewProviderData] = useState({
    npi_number: "",
    f_name: "",
    l_name: "",
    email: "",
    photo: "",
    calendar: "",
    taxonomy_description: "",
    is_active: 1,
    latitude: "",
    longitude: "",
    NPI_details: "",
    is_calendar_sync: 0,
  });
  const fileInputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [npiError, setNpiError] = useState("");
  const [organid, setOrganid] = useState("");
  const [isCalendarSynced, setIsCalendarSynced] = useState(false);

  function formatMobileNumber(value) {
    const numbersOnly = value.replace(/[^\d+]/g, "");

    if (numbersOnly.length <= 3) {
      return numbersOnly;
    } else if (numbersOnly.length <= 6) {
      return `(${numbersOnly.slice(0, 3)})-${numbersOnly.slice(3)}`;
    } else {
      return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
        3,
        6
      )}-${numbersOnly.slice(6)}`;
    }
  }

  function formatFaxNumber(faxNumber) {
    const numbersOnly = faxNumber.replace(/[^\d+]/g, "");

    if (numbersOnly.length !== 10) {
      return null;
    }

    return `+1 (${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
      3,
      6
    )}-${numbersOnly.slice(6)}`;
  }
  const handleSyncCalendar = async () => {
    // Simulate calendar sync process
    try {
      const currentDate = new Date().toISOString().split("T")[0];
      const timeMin = `${currentDate}T00:00:00Z`;
      const timeMax = `${currentDate}T23:59:59Z`;

      const payload = {
        user: newProviderData.calendar,
        timeMin,
        timeMax,
      };
      // console.log("payload", payload);
      // Simulate API call for calendar sync
      const response = await postData(
        "googlecalendar/checkPublicCalendar",
        payload
      );

      if (response.success == true) {
        setIsCalendarSynced(true);
        setNewProviderData((prevData) => ({
          ...prevData,
          is_calendar_sync: 1,
        }));
        Swal.fire({
          icon: "success",
          title: "Calendar Sync Successful",
          text: "Your Calendar Has Been Successfully Synced!",
        });
      } else {
        setIsCalendarSynced(false);
        setNewProviderData((prevData) => ({
          ...prevData,
          is_calendar_sync: 0,
        }));
        Swal.fire({
          icon: "error",
          title: "Sync Failed",
          text: response.message,
        });
      }
    } catch (error) {
      console.error("Error syncing calendar:", error);
      setIsCalendarSynced(false);
      setNewProviderData((prevData) => ({
        ...prevData,
        is_calendar_sync: 0,
      }));
      Swal.fire({
        icon: "error",
        title: "Sync Failed",
        text: error.message,
      });
    }
  };
  useEffect(() => {
    if (address) {
      getOrganiCoordinates(address);
    }
  }, [address]);

  useEffect(() => {
    if (provaddress) {
      getProviderCoordinates(provaddress);
    }
  }, [provaddress]);

  const getOrganiCoordinates = async (address) => {
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: address,
            format: "json",
            limit: 1,
          },
        }
      );

      if (response.data.length > 0) {
        const location = response.data[0];
        setLatitude(location.lat);
        setLongitude(location.lon);

        setOrganizationData((prevData) => ({
          ...prevData,
          latitude: location.lat,
          longitude: location.lon,
        }));
      }
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
      alert("Error fetching the coordinates.");
    }
  };
  const getProviderCoordinates = async (provaddress) => {
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: provaddress,
            format: "json",
            limit: 1,
          },
        }
      );

      if (response.data.length > 0) {
        const location = response.data[0];
        setLatitude(location.lat);
        setLongitude(location.lon);

        setNewProviderData((prevData) => ({
          ...prevData,
          latitude: location.lat,
          longitude: location.lon,
        }));
      }
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
      alert("Error fetching the coordinates.");
    }
  };
  useEffect(() => {
    const { npi_number } = newProviderData;

    if (npi_number && !isEditing) {
      const timeoutId = setTimeout(() => {
        handleFetchProviderData(npi_number);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [newProviderData.npi_number, isEditing]);

  useEffect(() => {
    // if (organizationData) {
    const { npi_number } = organizationData ? organizationData : "";
    // }
    if (npi_number) {
      const timeoutId = setTimeout(() => {
        handleFetchOrgnizationData(npi_number);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [organizationData?.npi_number]);

  const getNPINumberData = async (npi_number) => {
    try {
      const npiRegistered = await checkNpiRegistered(npi_number);
      if (npi_number && !npiRegistered) {
        setNpiError("This NPI number is not registered on NPPES.");
        setNewProviderData((prevData) => ({
          ...prevData,
          f_name: "",
          l_name: "",
          taxonomy_description: "",
          email: "",
          calendar: "",
        }));
        setProviderErrors({
          f_name: "",
          l_name: "",
          taxonomy_description: "",
          email: "",
          calendar: "",
        });
        return;
      }

      setNpiError("");
      const payload = {
        number: newProviderData.npi_number,
      };

      const response = await postData("practitioners/providerSearch", payload);

      const provNpiData = response.data;

      setProvNpiData(provNpiData);

      setProvAddress(response?.data?.addresses[0]?.addressLine1);
      const number = response?.data?.number || "";
      const firstName = response?.data?.basic?.firstName || "";
      const lastName = response?.data?.basic?.lastName || "";
      const specialization =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.specialization
          : "";
      const desc =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.desc
          : "";
      const taxonomyDescription = `${desc}  ${specialization}`;

      setNewProviderData((prevData) => ({
        ...prevData,
        npi_number: number || prevData.npi_number,
        f_name: firstName || prevData.f_name,
        l_name: lastName || prevData.l_name,
        taxonomy_description:
          taxonomyDescription || prevData.taxonomy_description,
        NPI_details: provNpiData,
        is_active: 1,
        latitude: latitude,
        longitude: longitude,
      }));
      setProviderErrors({
        f_name: "",
        l_name: "",
        taxonomy_description: "",
        email: "",
        calendar: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setNpiError("An error occurred while fetching NPI data.");
    }
  };

  const handleFetchProviderData = async () => {
    const { npi_number } = newProviderData;
    await getNPINumberData(npi_number);
  };

  const handleFetchOrgnizationData = async () => {
    const { npi_number } = organizationData;
    await getOrgNPINumberData(npi_number);
  };
  const formatZipcode = (zipcode) => {
    if (zipcode.length === 9) {
      return `${zipcode.substring(0, 5)}-${zipcode.substring(5)}`;
    }
    return zipcode;
  };
  const getOrgNPINumberData = async (npi_number) => {
    const npiRegistered = await checkNpiRegistered(npi_number);
    if (!npiRegistered) {
      setOrganizationData((prevData) => ({
        ...prevData,
        // npi_number: "",
        name: "",
        email: "",
        website: "",
        taxonomy_description: "",
        logo_image: "",
      }));
      setAddLocations([]);
      // setAddLocations((prevLocations) => prevLocations.slice(0, -1));
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
      });
      setErrors({
        npi_number: "",
        name: "",
        taxonomy_description: "",
        email: "",
      });
      setLocationError({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
      });
      return;
    }
    try {
      const payload = {
        number: organizationData.npi_number,
      };

      const response = await postData("practitioners/providerSearch", payload);

      var providerAddress = [];
      if (response.data.addresses[1]) {
        providerAddress = response.data.addresses[1];
      } else {
        providerAddress = response?.data?.addresses[0];
      }

      const orgNpiData = response.data;

      setOrgNpiData(orgNpiData);

      setAddress(providerAddress?.addressLine1);
      const number = response?.data?.number || "";
      const name = response?.data?.basic?.name || "";
      const teleNumber = providerAddress?.teleNumber || "";
      const faxNumber = providerAddress?.faxNumber || "";
      const streetAdd = providerAddress?.addressLine1 || "";
      const City = providerAddress?.city || "";
      const postalCode = providerAddress?.postalCode || "";
      const stateCode = providerAddress?.state || "";
      const countryCode = providerAddress?.countryCode || "";
      const specialization =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.specialization
          : "";
      const desc =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.desc
          : "";
      const taxonomyDescription = `${desc}  ${specialization}`;
      const email =
        response?.data?.endPoints?.length > 0
          ? response?.data?.endPoints[0]?.endpoint
          : "";

      const countryObj = countries.find(
        (country) => country.countryCode === countryCode
      );
      const stateObj = states.find((state) => state.stateCode === stateCode);

      const countryName = countryCode;
      const stateName = stateCode;

      setOrganizationData((prevData) => ({
        ...prevData,
        npi_number: number || "",
        name: name || "",
        // phone: teleNumber || "",
        // faxNumber: faxNumber || prevData.faxNumber,
        // street: streetAdd || "",
        // zipcode: formatZipcode(postalCode) || "",
        // country: countryName || "",
        // city: City || "",
        // state: stateName || "",
        taxonomy_description: taxonomyDescription || "",
        email: email || prevData.email,
        // address_ype: prevData.address_ype || "",
        website: prevData.website || "",
        logo_image: prevData.logo_image || "",
        NPI_details: orgNpiData,
      }));

      var LocationsData = {
        phone: formatMobileNumber(teleNumber),
        fax_number: faxNumber,
        street: streetAdd,
        city: City,
        state: stateName,
        country: countryName,
        zipcode: formatZippCode(postalCode),
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
      };
      // setAddLocations((prevLocations) => {
      //   const exists = prevLocations.some(
      //     (location) => location.phone === LocationsData.phone
      //   );

      //   if (!exists) {
      //     return [...prevLocations, LocationsData];
      //   }

      //   return prevLocations;
      // });
      // setAddLocations((prevLocations) => [...prevLocations, LocationsData]);
      const practiceLocations = response?.data?.practiceLocations || [];

      setAddLocations((prevLocations) => {
        const getLocationKey = (location) =>
          `${location.phone}-${location.street}-${location.city}`;

        // Convert practiceLocations to the desired format
        const practiceLocationsFormatted = practiceLocations.map(
          (location) => ({
            phone: formatMobileNumber(location.teleNumber) || "",
            fax_number: location.faxNumber || "",
            street: location.addressLine1 || "",
            city: location.city || "",
            state: location.state || "",
            country: location.countryCode || "",
            zipcode: formatZippCode(location.postalCode) || "",
            address_ype: "",
            is_active: 1,
            is_deleted: 0,
          })
        );

        // Create a map to track unique locations
        const locationMap = new Map();

        // Add existing locations to the map
        prevLocations.forEach((loc) =>
          locationMap.set(getLocationKey(loc), loc)
        );

        // Handle inclusion based on practiceLocations length
        if (practiceLocations.length > 0) {
          // Add all unique locations from practiceLocations
          practiceLocationsFormatted.forEach((loc) => {
            const key = getLocationKey(loc);
            if (!locationMap.has(key)) {
              locationMap.set(key, loc);
            }
          });

          // Add LocationsData to the list
          const formattedLocationsData = {
            phone: LocationsData.phone || "",
            fax_number: LocationsData.fax_number || "",
            street: LocationsData.street || "",
            city: LocationsData.city || "",
            state: LocationsData.state || "",
            country: LocationsData.country || "",
            zipcode: formatZipcode(LocationsData.zipcode) || "",
            address_ype: "",
            is_active: LocationsData.is_active || 0,
            is_deleted: LocationsData.is_deleted || 0,
          };
          const key = getLocationKey(formattedLocationsData);
          if (!locationMap.has(key)) {
            locationMap.set(key, formattedLocationsData);
          }
        } else {
          // Only include LocationsData if practiceLocations is empty
          const formattedLocationsData = {
            phone: LocationsData.phone || "",
            fax_number: LocationsData.fax_number || "",
            street: LocationsData.street || "",
            city: LocationsData.city || "",
            state: LocationsData.state || "",
            country: LocationsData.country || "",
            zipcode: LocationsData.zipcode || "",
            address_ype: LocationsData.address_ype || "",
            is_active: LocationsData.is_active || 0,
            is_deleted: LocationsData.is_deleted || 0,
          };
          const key = getLocationKey(formattedLocationsData);
          if (!locationMap.has(key)) {
            locationMap.set(key, formattedLocationsData);
          }
        }

        // Convert map values to array
        const uniqueLocations = Array.from(locationMap.values());

        return uniqueLocations;
      });

      setErrors({
        npi_number: "",
        name: "",
        taxonomy_description: "",
        email: "",
      });
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const handleIsActiveChange = async (locationId, checked) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "Do you want to change the status?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, change it!",
  //       cancelButtonText: "No, cancel!",
  //     });

  //     if (confirmationResult.isConfirmed) {
  //       const updatedLocations = addLocations.map((location) => {
  //         // if (location.id === locationId) {
  //         return {
  //           ...location,
  //           is_active: checked ? 1 : 0,
  //         };
  //         // }
  //         // return location;
  //       });

  //       setAddLocations(updatedLocations);

  //       Swal.fire({
  //         title: "Success",
  //         text: "Status Changed Successfully",
  //         icon: "success",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while updating isActive:", error);
  //     Swal.fire({
  //       title: "Error",
  //       text: "An error occurred. Please try again later.",
  //       icon: "error",
  //       showConfirmButton: true,
  //     });
  //   }
  // };

  const handleIsActiveChange = async (index, checked) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "Do You Want To Change The Status?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change It!",
        cancelButtonText: "No, Cancel!",
      });

      if (confirmationResult.isConfirmed) {
        setAddLocations((prevLocations) => {
          const updatedLocations = [...prevLocations];
          updatedLocations[index] = {
            ...updatedLocations[index],
            is_active: checked ? 1 : 0,
          };
          return updatedLocations;
        });

        // Swal.fire({
        //   title: "Success",
        //   text: "Status Changed Successfully",
        //   icon: "success",
        //   timer: 2000,
        //   showConfirmButton: false,
        // });
      }
    } catch (error) {
      console.error("An error occurred while updating isActive:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const handleChange = async (e) => {
    var { name, value, files } = e.target;
    if (name === "npi_number") {
      value = value.replace(/\D/g, "");
    } else if (name === "f_name" || name === "l_name") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    if (
      name === "taxonomy_description" ||
      name === "email" ||
      name === "calendar"
    ) {
      value = value.replace(/^\s+/, "");
    }
    if (e.target.type === "file" && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProviderData((prevData) => ({
          ...prevData,
          photo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setNewProviderData((prevData) => ({ ...prevData, [name]: value }));
    }

    setProviderErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "npi_number") {
      setNpiError("");
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;

    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault();
    }
  };

  const handleAddOrUpdateProvider = async () => {
    console.log("data");
    const providerErrors = await validateProviderFields(newProviderData);
    if (Object.keys(providerErrors).length === 0) {
      // Ensure providerData is always an array
      let updatedProviderData = Array.isArray(providerData)
        ? [...providerData]
        : [];

      const emailExists = updatedProviderData.some(
        (provider, index) =>
          provider.email === newProviderData.email && index !== editIndex
      );
      const npiExists = newProviderData.npi_number
        ? updatedProviderData.some(
            (provider, index) =>
              provider.npi_number === newProviderData.npi_number &&
              index !== editIndex
          )
        : false;

      if (emailExists) {
        setProviderErrors((prevErrors) => ({
          ...prevErrors,
          email: "Record Is Already Exist",
        }));
        return;
      }
      if (npiExists) {
        setProviderErrors((prevErrors) => ({
          ...prevErrors,
          npi_number: "Record With This NPI Number Already Exists",
        }));
        return;
      }

      if (editIndex >= 0) {
        updatedProviderData[editIndex] = newProviderData;
        setIsEditing(true);
      } else {
        updatedProviderData = [...updatedProviderData, newProviderData];
      }

      setProviderData(updatedProviderData);

      setNewProviderData({
        npi_number: "",
        f_name: "",
        l_name: "",
        email: "",
        photo: "",
        calendar: "",
        taxonomy_description: "",
        is_active: 1,
        latitude: "",
        longitude: "",
        NPI_details: "",
      });
      if (fileInputRef.current) fileInputRef.current.value = null;
      setEditIndex(-1);
      setNewProviderData((prev) => ({ ...prev, photo: null }));
      setIsEditing(false);
      setProviderErrors({});
      setNpiError("");
      setIsCalendarSynced(false);
    } else {
      setProviderErrors((prevErrors) => ({
        ...prevErrors,
        ...providerErrors,
      }));
    }
  };

  const handleEditProvider = (index) => {
    setNewProviderData(providerData[index]);
    setEditIndex(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      const savedOrganizationData =
        JSON.parse(localStorage.getItem("organizationData")) || {};
      const savedProviderData =
        JSON.parse(localStorage.getItem("providerData")) || [];
      setProviderData(savedProviderData);
      setOrganizationData((prevState) => ({
        ...prevState,
        ...savedOrganizationData,
      }));

      try {
        const response = await fetch(
          "https://npiregistry.cms.hhs.gov/RegistryBack/referenceData",
          {
            method: "POST",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();

        setCountries(data.countries || []);
        setStates(data.states || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const checkEmailExists = async (email) => {
    try {
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          email: email,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const checkNpiNumExists = async (npi_number) => {
    if (!npi_number) {
      return false;
    }
    try {
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          npi_number: npi_number,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const checkEmailOrgaExists = async (email) => {
    try {
      const payload = {
        modelName: "clinics",
        whereCondition: {
          email: email,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const checkNpiNumOrgExists = async (npi_number) => {
    if (!npi_number) {
      return false;
    }

    try {
      const payload = {
        modelName: "clinics",
        whereCondition: {
          npi_number: npi_number,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking NPI number existence:", error);
      return false;
    }
  };

  const checkNpiRegistered = async (npi_number) => {
    try {
      const response = await postData("practitioners/providerSearch", {
        number: npi_number,
      });
      return response.data && Object.keys(response.data).length > 0;
    } catch (error) {
      console.error("Error checking NPI number:", error);
      return false;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );

            const {
              clinics_data,
              practitioners_data,
              clinic_locations,
              providerisChecked,
            } = parsedPayloadData;

            setOrganizationData(clinics_data);
            setProviderData(practitioners_data);
            setProviderisChecked(providerisChecked);
            setAddLocations(clinic_locations);
          } else {
            console.error("No data found for organizationId:", organizationId);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);
  const OrganizationExists = async () => {
    try {
      const payload = {
        modelName: "subscription_temp",
        whereCondition: {
          email: organizationData.email,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        setOrganid(apiResponse.data[0].id);

        return apiResponse.data[0].id;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const handleLocationChange = (e) => {
    var { name, value } = e.target;

    if (name === "city") {
      value = value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");
    }
    if (name === "street") {
      value = value.replace(/^\s+/, "");
    }
    if (name === "fax_number") {
      let numericValue = value.replace(/\D/g, "");
      if (numericValue.length > 3 && numericValue.length <= 6) {
        numericValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
      } else if (numericValue.length > 6) {
        numericValue =
          numericValue.slice(0, 3) +
          "-" +
          numericValue.slice(3, 6) +
          "-" +
          numericValue.slice(6, 10);
      }
      value = numericValue;
    }
    if (name === "zipcode") {
      let numericValue = value.replace(/[^\d]/g, "");
      if (numericValue.length > 5) {
        numericValue = numericValue.slice(0, 5) + "-" + numericValue.slice(5);
      }
      value = numericValue;
    }
    if (name === "phone") {
      const formattedValue = formatMobileNumber(value);
      const newValue = formattedValue;
      setLocations({ ...locations, [name]: newValue });
    } else {
      setLocations({ ...locations, [name]: value });
    }
    setLocationError({ ...locationError, [name]: "" });
  };
  const handleOutpatientChange = (fieldName, value) => {
    if (fieldName === "phone") {
      const formattedValue = formatMobileNumber(value);

      if (formattedValue !== value) {
        setOrganizationData((prevState) => ({
          ...prevState,
          [fieldName]: formattedValue,
        }));
        return;
      }
    }

    if (fieldName === "name") {
      value = value.replace(/^\s+/, "");
    }

    // if (fieldName === "faxNumber") {
    //   value = value.replace(/\D/g, "");
    // }
    if (fieldName === "taxonomy_description") {
      value = value.replace(/^\s+/, "");
    }
    // if (fieldName === "city") {
    //   value = value.replace(/^\s+/, "");
    // }
    // if (fieldName === "zipcode") {
    //   let numericValue = value.replace(/[^\d]/g, "");
    //   if (numericValue.length > 5) {
    //     numericValue = numericValue.slice(0, 5) + "-" + numericValue.slice(5);
    //   }
    //   value = numericValue;
    // }
    // if (fieldName === "street") {
    //   value = value.replace(/^\s+/, "");
    // }
    if (fieldName === "email") {
      value = value.replace(/^\s+/, "");
    }
    if (fieldName === "website") {
      value = value.replace(/^\s+/, "");
    }

    if (fieldName === "npi_number") {
      const numbersOnly = value.replace(/[^\d]/g, "");

      if (numbersOnly !== value) {
        setOrganizationData((prevState) => ({
          ...prevState,
          [fieldName]: numbersOnly,
        }));
        return;
      }
    }
    setOrganizationData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  function formatZippCode(zipcode) {
    const cleanedZipcode = zipcode.replace(/\D/g, "");

    // Check if the cleaned zip code has 9 digits
    if (cleanedZipcode.length === 9) {
      // Format it as 'XXXXX-YYYY'
      return `${cleanedZipcode.slice(0, 5)}-${cleanedZipcode.slice(5)}`;
    } else {
      // Return the original zipcode if it does not have 9 digits
      return cleanedZipcode;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const { countries, states, ...dataToLog } = organizationData;
    const formData = {
      organizationData: dataToLog,
      providerData,
    };

    console.log(JSON.stringify(formData, null, 2));
  };

  const validateForm = (data) => {
    let isValid = true;
    const locationError = {};

    if (!data.phone) {
      isValid = false;
      locationError.phone = "Contact Number Is Required.";
    } else if (data.phone.replace(/\D/g, "").length < 10) {
      isValid = false;
      locationError.phone = "Contact Number Must Contain 10 Digits.";
    }

    if (!data.city) {
      isValid = false;
      locationError.city = "City Is Required";
    }
    if (!data.state) {
      isValid = false;
      locationError.state = "State Is Required";
    }
    if (!data.country) {
      isValid = false;
      locationError.country = "Country Is Required";
    }
    if (!data.address_ype) {
      isValid = false;
      locationError.address_ype = "Address Type Is Required";
    }
    if (!data.street) {
      isValid = false;
      locationError.street = "Street Address Is Required";
    }
    if (!data.zipcode) {
      isValid = false;
      locationError.zipcode = "Zip Code Is Required";
    } else if (!/^\d{5}(-\d{4})?$/.test(data.zipcode)) {
      isValid = false;
      locationError.zipcode = "Invalid Zip Code Format";
    }

    setLocationError(locationError);
    return isValid;
  };

  const addLocation = (e) => {
    // console.log("addlocation", addLocations);
    e.preventDefault();
    const isValid = validateForm(locations);
    if (!isValid) {
      return;
    }

    if (editingIndex !== null) {
      const updatedLocations = addLocations.map((loc, index) =>
        index === editingIndex ? locations : loc
      );
      setAddLocations(updatedLocations);

      setEditingIndex(null);
    } else {
      setAddLocations([...addLocations, locations]);
    }

    setLocations({
      phone: "",
      fax_number: "",
      street: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      address_ype: "",
      is_active: 1,
      is_deleted: 0,
    });
  };
  const editLocation = (index) => {
    const locationToEdit = addLocations[index];
    setLocations(locationToEdit);
    setEditingIndex(index);
  };
  const deleteLocation = async (index) => {
    try {
      if (index === editingIndex) {
        Swal.fire({
          title: "Cannot Delete!",
          text: "You Cannot Delete A Record While It Is Being Edited.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "You Won't Be Able To Revert This!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete It!",
      });

      if (confirmationResult.isConfirmed) {
        const updatedLocations = addLocations.map((location, i) => {
          if (i === index) {
            return { ...location, is_deleted: 1 };
          }
          return location;
        });
        setAddLocations(updatedLocations);

        // Swal.fire({
        //   title: "Deleted!",
        //   text: "The Location Has Been Successfully Deleted.",
        //   icon: "success",
        // });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateProviderFields = async (provider) => {
    let tempErrors = {};
    // if (!provider.npi_number) {
    //   tempErrors.npi_number = "Provider NPI Is Required.";
    // } else

    if (provider.npi_number && !/^\d{10}$/.test(provider.npi_number)) {
      tempErrors.npi_number = "Provider NPI Must Be A 10-Digit Number.";
    } else {
      try {
        const isNpiRegistered = await checkNpiRegistered(provider.npi_number);
        if (provider.npi_number && !isNpiRegistered) {
          // setNpiError("Please Enter A Valid Npi Number");
          tempErrors.npi_number = "Please Enter A Valid Npi Number";
        } else {
          const npiExists = await checkNpiNumExists(provider.npi_number);
          if (npiExists) {
            tempErrors.npi_number =
              "The Provider Is Already Registered With Samara-Well.";
          }
        }
      } catch (error) {
        console.error("Error checking NPI number:", error);
      }
    }
    if (!provider.f_name) {
      tempErrors.f_name = "First Name Is Required.";
    }
    if (!provider.l_name) {
      tempErrors.l_name = "Last Name Is Required.";
    }
    if (!provider.email) {
      tempErrors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(provider.email)
    ) {
      tempErrors.email = "Email Is Invalid.";
    } else {
      const emailExists = await checkEmailExists(provider.email);
      if (emailExists) {
        tempErrors.email =
          "The Provider Email Is Already Registered With Samara-Well.";
      }
    }
    // if (!provider.calendar) {
    //   tempErrors.calendar = "Provider Calender Is Required.";
    // }
    if (!provider.taxonomy_description) {
      tempErrors.taxonomy_description = "Provider Taxonomy Is Required.";
    }
    // if (!provider.photo) tempErrors.photo = "Profile Photo Is Required.";

    return tempErrors;
  };

  const validateOrganizationFields = async () => {
    let tempErrors = {};
    if (!organizationData?.name) {
      tempErrors.name = "Organization Name Is Required.";
    } else if (/^\s/.test(organizationData.name)) {
      tempErrors.name = "Organization Name Should Not Start With A Space.";
    } else if (/^\d+$/.test(organizationData.name)) {
      tempErrors.name = "Organization Name Should Not Contain Only Numbers.";
    } else if (/^[^\w\s]+$/.test(organizationData.name)) {
      tempErrors.name = "Organization Name Should Not Contain Only Symbols.";
    } else if (organizationData.name.length < 3) {
      tempErrors.name =
        "Organization Name Should Be At Least 3 Characters Long.";
    }

    if (!organizationData?.email) {
      tempErrors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
        organizationData.email
      )
    ) {
      tempErrors.email = "Invalid Email Format.";
    } else {
      const emailExists123 = await checkEmailOrgaExists(organizationData.email);
      if (emailExists123) {
        tempErrors.email =
          "The Organization Email Is Already Registered With Samara-Well.";
      }
    }

    if (/^\s/.test(organizationData?.website)) {
      tempErrors.website = "Website Should Not Start With A Space.";
    } else if (
      organizationData?.website &&
      !/^https?:\/\/(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/.test(
        organizationData.website
      )
    ) {
      tempErrors.website = "Valid Website is Required.";
    }

    if (
      organizationData?.npi_number &&
      !/^\d{10}$/.test(organizationData?.npi_number)
    ) {
      tempErrors.npi_number = "Organization NPI Must Be Exactly 10 Digits.";
    }

    // else {
    //   const npiExists = await checkNpiNumOrgExists(
    //     organizationData?.npi_number
    //   );
    //   console.log("The Organization ", npiExists);
    //   if (npiExists) {
    //     tempErrors.npi_number =
    //       "The Organization Is Already Registered With Samara-Well.";
    //   }
    else {
      try {
        const isNpiRegistered = await checkNpiRegistered(
          organizationData.npi_number
        );
        if (organizationData?.npi_number && !isNpiRegistered) {
          tempErrors.npi_number = "Please Enter A Valid Npi Number";
        } else {
          const npiExists = await checkNpiNumOrgExists(
            organizationData.npi_number
          );
          if (organizationData?.npi_number && !npiExists) {
            tempErrors.npi_number =
              "The Provider Is Already Registered With Samara-Well.";
          }
        }
      } catch (error) {
        console.error("Error checking NPI number:", error);
        // Handle error appropriately or set a generic error message
      }
    }

    if (!organizationData?.taxonomy_description) {
      tempErrors.taxonomy_description =
        "Organization Taxonomy Description Is Required.";
    } else if (/^\s/.test(organizationData.taxonomy_description)) {
      tempErrors.taxonomy_description =
        "Organization Taxonomy Description Should Not Start With A Space.";
    }
    if (!organizationData?.isChecked) {
      tempErrors.isChecked =
        "You Must Verify The Information And Provide Your Consent.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleOrganizationClick = async (event) => {
    const isValid = await validateOrganizationFields();
    if (!isValid) {
      event.preventDefault();
      return;
    }

    if (addLocations?.length === 0 || addLocations === undefined) {
      setErrorMessage("Please Add Organization Location");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }

    setErrorMessage("");

    const { countries, states, ...dataToSave } = organizationData;

    try {
      setLoading(true);
      console.log("loading started");
      const existingOrganizationId = await OrganizationExists();

      let payload;
      if (existingOrganizationId) {
        const fetchPayload = {
          modelName: "subscription_temp",
          whereCondition: {
            id: parseInt(existingOrganizationId),
          },
        };

        const apiResponse = await postData(
          "masters/getMasterList",
          fetchPayload
        );

        let existingData = {};
        if (apiResponse.code === 1 && apiResponse.data.length > 0) {
          existingData = JSON.parse(apiResponse.data[0].payload_data);
        }

        const updatedData = {
          ...existingData,
          clinics_data: dataToSave,

          clinic_locations: addLocations,
        };

        payload = {
          modelName: "subscription_temp",
          id: existingOrganizationId,
          inputData: {
            email: organizationData.email,
            payload_data: JSON.stringify(updatedData),
          },
        };
      } else {
        payload = {
          modelName: "subscription_temp",
          inputData: {
            email: organizationData.email,
            payload_data: JSON.stringify({
              clinics_data: dataToSave,
              // practitioners_data: providerData,
              clinic_locations: addLocations,
            }),
          },
        };
      }

      const response = await postData("masters/createAndUpdateMaster", payload);

      const { id, email } = response.data;

      localStorage.setItem("organizationId", id);
      localStorage.setItem("organizationEmail", email);
      setLoading(false);
      // navigate("/serviceToProvider", {
      //   state: { organizationData, providerData, addLocations },
      // });
      setShowProviderSection(true);
    } catch (error) {
      setLoading(false);
      console.error("Error saving data:", error);
      setErrorMessage("Failed to save data. Please try again.");
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };
  const handleProvidersClick = async (event) => {
    const isValid = await validateOrganizationFields();
    if (!isValid) {
      event.preventDefault();
      return;
    }

    if (providerData?.length === 0 || providerData === undefined) {
      Swal.fire({
        title: "Error",
        text: "Please Add At Least One Provider Before Mapping",
        icon: "error",
        timer: 2000,
        showConfirmButton: true,
      });
      return;
    }
    if (!providerisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    setErrorMessage("");

    const { countries, states, ...dataToSave } = organizationData;

    try {
      setLoading(true);
      console.log("loading started");
      // const organizationId = localStorage.getItem("organizationId");
      const existingOrganizationId = await OrganizationExists();
      let payload;

      if (existingOrganizationId) {
        const fetchPayload = {
          modelName: "subscription_temp",
          whereCondition: {
            id: parseInt(existingOrganizationId),
          },
        };

        const apiResponse = await postData(
          "masters/getMasterList",
          fetchPayload
        );

        let existingData = {};
        if (apiResponse.code === 1 && apiResponse.data.length > 0) {
          existingData = JSON.parse(apiResponse.data[0].payload_data);
        }

        const updatedData = {
          ...existingData,
          clinics_data: dataToSave,
          practitioners_data: providerData,
          providerisChecked: providerisChecked,
          clinic_locations: addLocations,
        };
        // console.log("updatedData", updatedData);
        payload = {
          modelName: "subscription_temp",
          id: existingOrganizationId,
          inputData: {
            email: organizationData.email,
            payload_data: JSON.stringify(updatedData),
          },
        };
      } else {
        payload = {
          modelName: "subscription_temp",
          inputData: {
            email: organizationData.email,
            payload_data: JSON.stringify({
              clinics_data: dataToSave,
              practitioners_data: providerData,
              providerisChecked: providerisChecked,
              clinic_locations: addLocations,
            }),
          },
        };
      }

      const response = await postData("masters/createAndUpdateMaster", payload);

      setLoading(false);

      navigate("/serviceToProvider", {
        state: {
          organizationData,
          providerData,
          addLocations,
          providerisChecked,
        },
      });
    } catch (error) {
      setLoading(false);
      console.error("Error saving data:", error);
      setErrorMessage("Failed to save data. Please try again.");
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  const handleDeleteProvider = (index) => {
    if (index === editIndex) {
      Swal.fire({
        title: "Cannot Delete!",
        text: "You Cannot Delete A Record While It Is Being Edited.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
      return;
    }

    const updatedProviderData = providerData.filter((_, i) => i !== index);
    setProviderData(updatedProviderData);
  };
  const handleLogoImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const logoImageBase64 = e.target.result.toString();
          setLogoImage(logoImageBase64);
          setOrganizationData((prevData) => ({
            ...prevData,
            logo_image: logoImageBase64,
          }));
          //localStorage.setItem("logoImage", logoImageBase64);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const toggleEnlargeImage = (image) => {
    setEnlargedImageUrl(image);
    setEnlargeImage(!enlargeImage);
  };
  const getLabelByCode = (code, options) => {
    const option = options.find((option) => option.value === code);
    return option ? option.label : code;
  };
  return (
    <>
      {loading && <Loader2 />}
      {/* ) : ( */}
      <>
        <Header />
        <div className="pt-16 content">
          {/* <div className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5">
            <StepsForRegistration />
          </div>
          <h1 className="font-fontPoppins text-3xl text-center pb-3 font-semibold  leading-normal"></h1> */}
          <div className="container mx-auto px-4 flex items-center justify-between gap-16 xss:gap-2 sm:gap-5 relative">
            <StepsForRegistration />
            <h1 className="font-fontPoppins md:text-xl font-semibold text-xs xss:text-xs sm:text-sm leading-normal absolute left-1/2  sm:left-1/2 sm:transform sm:-translate-x-1/2 xss:right-0">
              Outpatient Practice Details
            </h1>
          </div>
          {!showProviderSection && (
            <section className="w-full">
              <div className="container mx-auto px-4">
                <div className="bg-grayColorLight rounded-lg p-3">
                  <h2 className="font-fontPoppins text-lg font-bold text-center text-gray-700 bg-gray-100  rounded shadow-md mb-2">
                    Please Enter The Details For Your Organization
                  </h2>
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Organization NPI Number
                        {/* <span className="text-red-500">*</span> */}
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        value={organizationData?.npi_number}
                        placeholder="Enter Organization NPI Number"
                        maxLength={10}
                        onChange={(e) =>
                          handleOutpatientChange("npi_number", e.target.value)
                        }
                      />
                      {errors.npi_number && (
                        <p className="text-red-500 text-xs">
                          {errors.npi_number}
                        </p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Organization Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={organizationData?.name}
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        placeholder="Enter Organization Name"
                        // readOnly
                        onChange={(e) =>
                          handleOutpatientChange("name", e.target.value)
                        }
                      />
                      {errors.name && (
                        <p className="text-red-500 text-xs">{errors.name}</p>
                      )}
                    </div>
                  </div>

                  <div className="w-full mt-3">
                    <div className="w-full md:flex items-center gap-3">
                      <div className="w-full md:w-1/2">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Email
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          name="email"
                          value={organizationData?.email}
                          onChange={(e) =>
                            handleOutpatientChange("email", e.target.value)
                          }
                          placeholder="Enter Organization Email"
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs">{errors.email}</p>
                        )}
                      </div>
                      <div className="w-full md:w-1/2 mt-3 md:mt-0">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Logo Image
                        </label>
                        <div className="flex items-center">
                          <input
                            onChange={handleLogoImageUpload}
                            className="w-full h-9 rounded-full text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                            style={{
                              paddingLeft: "7px",
                              paddingTop: "7px",
                            }}
                            type="file"
                            accept="image/*"
                            placeholder="Upload"
                          />
                          {organizationData?.logo_image && (
                            <img
                              src={organizationData?.logo_image}
                              alt="Logo Image"
                              className="w-12 h-12 ml-2 cursor-pointer"
                              onClick={() =>
                                toggleEnlargeImage(organizationData?.logo_image)
                              }
                            />
                          )}
                          {enlargeImage && (
                            <div
                              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                              onClick={toggleEnlargeImage}
                            >
                              <div className="fixed inset-0 flex items-center justify-center">
                                <div className="w-100 aspect-square overflow-hidden">
                                  <img
                                    src={organizationData?.logo_image}
                                    alt="Enlarged"
                                    className="object-contain w-full h-full"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:flex items-center gap-3 mt-3">
                      <div className="w-full md:w-1/2 mt-3 md:mt-0">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Taxonomy Description
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          value={organizationData?.taxonomy_description}
                          placeholder="Enter Organization Taxonomy"
                          onChange={(e) =>
                            handleOutpatientChange(
                              "taxonomy_description",
                              e.target.value
                            )
                          }
                        />
                        {errors.taxonomy_description && (
                          <p className="text-red-500 text-xs mt-1">
                            {errors.taxonomy_description}
                          </p>
                        )}
                      </div>
                      <div className="w-full md:w-1/2 mt-3 md:mt-0">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Website
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          value={organizationData?.website}
                          placeholder="Website URL"
                          onChange={(e) =>
                            handleOutpatientChange("website", e.target.value)
                          }
                        />
                        {errors.website && (
                          <p className="text-red-500 text-xs">
                            {errors.website}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-grayColorLight rounded-lg p-3 mt-6">
                  <label className="font-fontPoppins text-xl text-center font-semibold m-3">
                    Organization Locations
                    <span className="text-red-500">*</span>
                  </label>

                  <div className="w-full bg-grayColor rounded-lg p-3 mt-1">
                    <div className="w-full md:flex justify-between gap-3">
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Contact Number
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="(xxx)-xxx-xxxx"
                          name="phone"
                          value={locations.phone}
                          onChange={handleLocationChange}
                          maxLength={14}
                          pattern="^\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$|^(\+\d{1,2}\s)?\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$"
                        />
                        {locationError.phone && (
                          <p className="text-red-500 text-xs">
                            {locationError.phone}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Street Address
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Enter Your Address"
                          name="street"
                          value={locations.street}
                          onChange={handleLocationChange}
                        />
                        {locationError.street && (
                          <p className="text-red-500 text-xs">
                            {locationError.street}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          City<span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Enter Your City"
                          value={locations.city}
                          onChange={handleLocationChange}
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        />
                        {locationError.city && (
                          <p className="text-red-500 text-xs">
                            {locationError.city}
                          </p>
                        )}
                      </div>

                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          State<span className="text-red-500">*</span>
                        </label>

                        <CustomSelectHealth
                          // className="w-full"
                          options={states.map((state) => ({
                            value: state.stateCode,
                            label: state.stateName,
                          }))}
                          name="state"
                          onChange={handleLocationChange}
                          value={locations.state}
                          placeholder={"Select Your State"}
                        />
                        {locationError.state && (
                          <p className="text-red-500 text-xs">
                            {locationError.state}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="w-full md:flex justify-between gap-3 mt-3">
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Country<span className="text-red-500">*</span>
                        </label>

                        <CustomSelectHealth
                          className="w-full"
                          name="country"
                          options={countries.map((country) => ({
                            value: country.countryCode,
                            label: country.countryName,
                          }))}
                          value={locations.country}
                          onChange={handleLocationChange}
                          placeholder="Select Your Country"
                        />
                        {locationError.country && (
                          <p className="text-red-500 text-xs">
                            {locationError.country}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Zip Code<span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Enter Zipcode"
                          name="zipcode"
                          value={locations.zipcode}
                          onChange={handleLocationChange}
                          maxLength={10}
                        />
                        {locationError.zipcode && (
                          <p className="text-red-500 text-xs">
                            {locationError.zipcode}
                          </p>
                        )}
                      </div>

                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Address Type
                          <span className="text-red-500">*</span>
                        </label>

                        <CustomSelectHealth
                          className="w-full"
                          name="address_ype"
                          options={[
                            { value: "Primary", label: "Primary" },
                            { value: "Secondary", label: "Secondary" },
                          ]}
                          value={locations.address_ype}
                          onChange={handleLocationChange}
                          placeholder={"Select Your Address Type"}
                        />

                        {locationError.address_ype && (
                          <p className="text-red-500 text-xs">
                            {locationError.address_ype}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Fax Number
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Enter Your Fax Number"
                          name="fax_number"
                          value={locations.fax_number}
                          onChange={handleLocationChange}
                          maxLength={12}
                        />
                      </div>
                      <div className="w-64 mt-3">
                        <label className="font-fontPoppins text-xs font-semibold text-transparent">
                          .
                        </label>
                        <button
                          type="submit"
                          onClick={addLocation}
                          className="w-full h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                        >
                          {editingIndex !== null ? "Update" : "Add"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="w-full mt-3 overflow-auto">
                    <table className="border-collapse table-auto w-full text-sm">
                      <thead>
                        <tr>
                          <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Contact Number
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Street Address
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            City
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            State
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Country
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b dark:border-slate-600 font-semibold p-3 pl-8 pb-3 text-left">
                            Zip Code
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Address Type
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Fax Number
                          </th>
                          <th className="font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Status
                          </th>
                          <th className="rounded-tr-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {addLocations &&
                          addLocations?.map(
                            (location, index) =>
                              location.is_deleted == 0 && (
                                <tr key={index}>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.phone}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.street}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.city}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {/* {location.state} */}
                                    {getLabelByCode(
                                      location.state,
                                      states.map((state) => ({
                                        value: state.stateCode,
                                        label: state.stateName,
                                      }))
                                    )}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {/* {location.country} */}
                                    {getLabelByCode(
                                      location.country,
                                      countries.map((country) => ({
                                        value: country.countryCode,
                                        label: country.countryName,
                                      }))
                                    )}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.zipcode}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.address_ype}
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    {location.fax_number}
                                  </td>

                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    <div className="flex items-center">
                                      <Switch
                                        checked={location.is_active === 1}
                                        onChange={(checked) =>
                                          // handleIsActiveChange(location.id, checked)
                                          handleIsActiveChange(index, checked)
                                        }
                                        className={`${
                                          location.is_active === 1
                                            ? "bg-green-600"
                                            : "bg-red-500"
                                        } relative inline-flex items-center h-8 rounded-full w-20 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                      >
                                        {location.is_active === 1 ? (
                                          <>
                                            <span className="absolute left-2 text-white sm:left-1">
                                              Active
                                            </span>
                                            <span className="translate-x-12 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                                          </>
                                        ) : (
                                          <>
                                            <span className="absolute right-2 text-white sm:right-1">
                                              Inactive
                                            </span>
                                            <span className="translate-x-1 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                                          </>
                                        )}
                                      </Switch>
                                    </div>
                                  </td>
                                  <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                    <span
                                      onClick={() => editLocation(index)}
                                      className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor"
                                    >
                                      <i className="fa-solid fa-pencil"></i>
                                    </span>

                                    <span
                                      onClick={() => deleteLocation(index)}
                                      className="text-gray-700 mr-2 cursor-pointer hover:text-red-500"
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </span>
                                  </td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={organizationData?.isChecked}
                      onChange={(e) =>
                        handleOutpatientChange("isChecked", e.target.checked)
                      }
                    />
                    <span className="font-fontPoppins text-xs font-semibold">
                      I have verified all the information and provide consent to
                      Samara Well to use and display this information
                      accordingly.
                    </span>
                  </div>
                  {errors.isChecked && (
                    <p className="text-red-500 text-xs">{errors.isChecked}</p>
                  )}

                  <div className="w-full mt-4 text-center">
                    <button
                      className="w-56 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
                      onClick={handleOrganizationClick}
                    >
                      <span className="me-2">Add Providers</span>
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </div>
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                </div>
              </div>
            </section>
          )}
          {showProviderSection && (
            <section className=" px-4 container mx-auto ">
              <div className="bg-grayColorLight rounded-lg p-3 mt-6">
                <h2 className="font-fontPoppins text-lg font-bold text-center text-gray-700 bg-gray-100 px-2 rounded shadow-md mb-2">
                  Add Providers
                </h2>
                <div className="w-full bg-grayColor rounded-lg p-3 mt-1">
                  <div className="w-full md:flex justify-between gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Provider NPI Number
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="npi_number"
                        value={newProviderData.npi_number}
                        maxLength={10}
                        placeholder="Enter Provider NPI Number"
                        onChange={handleChange}
                      />
                      {providerErrors.npi_number && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.npi_number}
                        </p>
                      )}
                      {npiError && !providerErrors.npi_number && (
                        <p className="text-red-500 text-xs">{npiError}</p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        First Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="f_name"
                        value={newProviderData.f_name}
                        placeholder="Enter First Name"
                        // readOnly
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                      {providerErrors.f_name && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.f_name}
                        </p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Last Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="l_name"
                        value={newProviderData.l_name}
                        placeholder="Enter Last Name"
                        // readOnly
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                      {providerErrors.l_name && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.l_name}
                        </p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Provider Email
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="email"
                        value={newProviderData.email}
                        placeholder="Enter Email"
                        onChange={handleChange}
                      />
                      {providerErrors.email && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full md:flex gap-3 mt-3">
                    <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Profile Photo
                      </label>
                      <div className="w-full relative">
                        <span className="z-10 absolute left-3 top-3">
                          <i className="fa-solid fa-camera"></i>
                        </span>
                        <input
                          className="w-full h-9 text-center indent-5 text-xs rounded-full px-4 py-1 font-fontPoppins text-gray-700 mt-2 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="file"
                          name="photo"
                          placeholder="Upload"
                          accept="image/jpeg, image/png"
                          onChange={handleChange}
                          ref={fileInputRef}
                        />
                        {newProviderData.photo && (
                          <img
                            src={
                              newProviderData.photo.startsWith("data:")
                                ? newProviderData.photo
                                : `${apiUrl}/${newProviderData.photo}` ||
                                  "/placeholder.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/placeholder.png";
                            }}
                            alt="photo"
                            className="absolute top-1/2 transform -translate-y-1/2 right-2 w-9 h-9 rounded-full"
                          />
                        )}
                        {providerErrors.photo && (
                          <p className="text-red-500 text-xs">
                            {providerErrors.photo}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Provider Calender
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center mt-1">
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          name="calendar"
                          value={newProviderData.calendar}
                          placeholder="Enter Provider Calendar"
                          onChange={handleChange}
                        />
                        {!isCalendarSynced && (
                          <span
                            className="cursor-pointer hover:text-blueColor ml-2 flex items-center"
                            onClick={handleSyncCalendar}
                          >
                            <i className="fa-solid fa-calendar-plus mr-2"></i>
                            Sync
                          </span>
                        )}
                      </div>
                      {providerErrors.calendar && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.calendar}
                        </p>
                      )}
                    </div> */}
                    <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Provider Taxonomy Description
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="taxonomy_description"
                        value={newProviderData.taxonomy_description}
                        placeholder="Enter Provider Taxonomy"
                        // readOnly
                        onChange={handleChange}
                      />
                      {providerErrors.taxonomy_description && (
                        <p className="text-red-500 text-xs">
                          {providerErrors.taxonomy_description}
                        </p>
                      )}
                    </div>
                    <div className="w-52">
                      <label className="font-fontPoppins text-xs font-semibold text-transparent">
                        .
                      </label>
                      <button
                        className="w-full h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                        onClick={handleAddOrUpdateProvider}
                      >
                        {editIndex >= 0 ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-3 overflow-auto">
                  <table className="border-collapse table-auto w-full text-sm">
                    <thead>
                      <tr>
                        <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Provider NPI Number
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Provider First Name
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Provider Last Name
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Provider Email
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Profile Photo
                        </th>
                        {/* <th className="font-fontPoppins text-xs bg-grayColor border-b dark:border-slate-600 font-semibold p-3 pl-8 pb-3 text-left">
                          Provider Calendar
                        </th> */}
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Provider Taxanomy
                        </th>
                        <th className="rounded-tr-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {providerData?.length > 0 &&
                        providerData?.map((provider, index) => (
                          <tr key={index}>
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.npi_number
                                ? provider.npi_number
                                : "N/A"}
                            </td>
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.f_name}
                            </td>
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.l_name}
                            </td>
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.email}
                            </td>
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              <img
                                src={provider.photo || "/placeholder.png"}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "/placeholder.png";
                                }}
                                alt="photo"
                                className="h-10 w-10 rounded-full"
                              />
                            </td>
                            {/* <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.calendar}
                            </td> */}
                            <td className="border-b border-slate-200 p-3 pl-8 text-left">
                              {provider.taxonomy_description}
                            </td>
                            <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                              <div className="flex items-center">
                                <span
                                  className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor flex items-center"
                                  onClick={() => handleEditProvider(index)}
                                >
                                  <i className="fa-solid fa-pencil mr-1"></i>{" "}
                                  Edit
                                </span>

                                <span
                                  className="text-gray-700 mr-2 cursor-pointer hover:text-red-500 flex items-center"
                                  onClick={() => handleDeleteProvider(index)}
                                >
                                  <i className="fa-solid fa-trash mr-1"></i>{" "}
                                  Delete
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-full mt-4">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={providerisChecked}
                    onChange={(e) => setProviderisChecked(e.target.checked)}
                  />
                  {/* <input
                    type="checkbox"
                    checked={organizationData?.isChecked}
                    onChange={(e) =>
                      handleOutpatientChange("isChecked", e.target.checked)
                    }
                  /> */}
                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500 text-xs absolute">
                    {errorMessage}
                  </p>
                )}
                <div className="w-full mt-5 flex justify-center gap-3 items-center text-center">
                  <button
                    className="w-32 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
                    onClick={() => setShowProviderSection(false)}
                  >
                    <i className="fa-solid fa-arrow-left-long"></i>
                    <span className="m-2">Back</span>
                  </button>
                  <button
                    className="w-56 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
                    onClick={handleProvidersClick}
                  >
                    <span className="me-2">Map Providers & Services</span>
                    <i className="fa-solid fa-arrow-right-long"></i>
                  </button>
                </div>

                {errors.isChecked && (
                  <p className="text-red-500 text-xs">{errors.isChecked}</p>
                )}
              </div>
            </section>
          )}

          <Footer />
        </div>
      </>
      {/* )} */}
    </>
  );
}

export default OutpatientPracticeDetails2;
