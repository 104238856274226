import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import StepsForRegistration from "../StepsForRegistration";
import { postData } from "../../Networking/Api";
import CustomSelect from "../CustomSelect";
import CustomSelectHealth from "../CustomSelectHealth";
import Loader from "../Loader";
import Loader2 from "../Loader2";
import swal from "sweetalert";
import Swal from "sweetalert2";

const ItemTypes = {
  PROVIDER: "provider",
};

const timeOptions = [
  { time: "12:00", label: "12:00" },
  { time: "12:15", label: "12:15" },
  { time: "12:30", label: "12:30" },
  { time: "12:45", label: "12:45" },
  { time: "1:00", label: "1:00" },
  { time: "1:15", label: "1:15" },
  { time: "1:30", label: "1:30" },
  { time: "1:45", label: "1:45" },
  { time: "2:00", label: "2:00" },
  { time: "2:15", label: "2:15" },
  { time: "2:30", label: "2:30" },
  { time: "2:45", label: "2:45" },
  { time: "3:00", label: "3:00" },
  { time: "3:15", label: "3:15" },
  { time: "3:30", label: "3:30" },
  { time: "3:45", label: "3:45" },
  { time: "4:00", label: "4:00" },
  { time: "4:15", label: "4:15" },
  { time: "4:30", label: "4:30" },
  { time: "4:45", label: "4:45" },
  { time: "5:00", label: "5:00" },
  { time: "5:15", label: "5:15" },
  { time: "5:30", label: "5:30" },
  { time: "5:45", label: "5:45" },
  { time: "6:00", label: "6:00" },
  { time: "6:15", label: "6:15" },
  { time: "6:30", label: "6:30" },
  { time: "6:45", label: "6:45" },
  { time: "7:00", label: "7:00" },
  { time: "7:15", label: "7:15" },
  { time: "7:30", label: "7:30" },
  { time: "7:45", label: "7:45" },
  { time: "8:00", label: "8:00" },
  { time: "8:15", label: "8:15" },
  { time: "8:30", label: "8:30" },
  { time: "8:45", label: "8:45" },
  { time: "9:00", label: "9:00" },
  { time: "9:15", label: "9:15" },
  { time: "9:30", label: "9:30" },
  { time: "9:45", label: "9:45" },
  { time: "10:00", label: "10:00" },
  { time: "10:15", label: "10:15" },
  { time: "10:30", label: "10:30" },
  { time: "10:45", label: "10:45" },
  { time: "11:00", label: "11:00" },
  { time: "11:15", label: "11:15" },
  { time: "11:30", label: "11:30" },
  { time: "11:45", label: "11:45" },
];

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Provider = ({
  name,
  src,
  email,
  setIsSlotModelOpen,
  isSlotModalOpen,
  providerTimeslots,
  setProviderTimeSlots,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.PROVIDER,
    item: { name, src, email },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const initialCheckedDays = weekDays.map(
    (day) => !!providerTimeslots[email]?.timeslots?.[day]?.length
  );
  const [localTimeslots, setLocalTimeslots] = useState(
    providerTimeslots[email]?.timeslots || {}
  );

  const [localCheckedDays, setLocalCheckedDays] = useState(initialCheckedDays);
  const [checkedDays, setCheckedDays] = useState(initialCheckedDays);
  const [timeslots, setTimeslots] = useState(
    providerTimeslots[email]?.timeslots || {}
  );
  const [saveError, setSaveError] = useState(false);
  const [isActive, setIsActive] = useState(
    providerTimeslots[email]?.activeStatus ?? true
  );
  const [selectedProvider, setSelectedProvider] = useState("");
  const [error, setError] = useState(false);
  const [timeslotError, setTimeSlotError] = useState("");

  useEffect(() => {
    if (email) {
      const filteredTimeslots = {};
      for (const [day, slots] of Object.entries(timeslots)) {
        if (slots.length > 0) {
          filteredTimeslots[day] = slots;
        }
      }

      setProviderTimeSlots((prev) => ({
        ...prev,
        [email]: {
          timeslots: filteredTimeslots,
          activeStatus: isActive,
        },
      }));
    }
  }, [timeslots, email, setProviderTimeSlots, setIsActive]);

  const handleCheckboxChange = (index) => {
    setLocalTimeslots((prev) => {
      return {
        ...prev,
        [day]: [{ startTime: "", endTime: "", ampmStart: "", ampmEnd: "" }],
      };
    });
    const day = weekDays[index];
    const newCheckedDays = [...localCheckedDays];
    newCheckedDays[index] = !newCheckedDays[index];

    setLocalTimeslots((prev) => ({
      ...prev,
      [day]: newCheckedDays[index] ? prev[day] || [] : [],
    }));

    setLocalCheckedDays(newCheckedDays);
  };
  const toMinutes = (time, ampm) => {
    const [hours, minutes] = time.split(":").map(Number);
    let hour24 = hours;
    if (ampm === "PM" && hours < 12) hour24 += 12;
    if (ampm === "AM" && hours === 12) hour24 = 0;
    return hour24 * 60 + minutes;
  };

  const doesOverlap = (newSlot, existingSlots) => {
    const newStart = toMinutes(newSlot.startTime, newSlot.ampmStart);
    const newEnd = toMinutes(newSlot.endTime, newSlot.ampmEnd);

    for (const slot of existingSlots) {
      const existingStart = toMinutes(slot.startTime, slot.ampmStart);
      const existingEnd = toMinutes(slot.endTime, slot.ampmEnd);

      const adjustedNewEnd = newStart > newEnd ? newEnd + 24 * 60 : newEnd;
      const adjustedExistingEnd =
        existingStart > existingEnd ? existingEnd + 24 * 60 : existingEnd;

      if (newStart < adjustedExistingEnd && adjustedNewEnd > existingStart) {
        return true;
      }
    }
    return false;
  };

  const addTimeslot = (day) => {
    setLocalTimeslots((prev) => {
      const daySlots = prev[day] || [];
      const lastSlot = daySlots[daySlots.length - 1];

      const hasIncompleteSlot = daySlots.some(
        (slot) =>
          !slot.startTime || !slot.endTime || !slot.ampmStart || !slot.ampmEnd
      );

      if (hasIncompleteSlot) {
        setError(
          "Please Fill Out All Existing Timeslots Before Adding A New One."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      const previousSlots = daySlots.slice(0, -1);

      if (
        previousSlots.length > 0 &&
        lastSlot &&
        doesOverlap(lastSlot, previousSlots)
      ) {
        setError(
          "The New Timeslot Overlaps With An Existing Timeslot. Please Adjust The Timeslot."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      // if (lastSlot && lastSlot.startTime === lastSlot.endTime) {
      //   setError(
      //     "The Start Time And End Time Of The Timeslot Cannot Be The Same. Please Adjust The Timeslot."
      //   );
      //   setTimeout(() => setError(""), 4000);
      //   return prev;
      // }

      const convertTimeToMinutes = (time, period) => {
        let [hour, minute] = time.split(":").map(Number);

        // Convert to 24-hour format
        if (period.toUpperCase() === "PM" && hour !== 12) {
          hour += 12;
        } else if (period.toUpperCase() === "AM" && hour === 12) {
          hour = 0;
        }

        return hour * 60 + minute;
      };
      const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
      const cutoffTimeEnd = convertTimeToMinutes("8:00", "PM");
      const isValidSlot = (slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        return startTime >= cutoffTimeStart && endTime <= cutoffTimeEnd;
      };

      if (lastSlot && !isValidSlot(lastSlot)) {
        setError(
          "Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }
      console.log(lastSlot);
      const isOvernightSlot = (slot) => {
        if (!slot || typeof slot !== "object") return false;

        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        // If end time is before start time, it's an overnight slot
        if (startTime === endTime) {
          return true;
        }

        if (endTime < startTime) {
          return true;
        }

        return false;
      };
      if (lastSlot && isOvernightSlot(lastSlot)) {
        setError(
          // "Overnight Timeslots Are Not Allowed. Please Adjust The Timeslot."
          "Invalid Timeslot, Please Select A valid Timeslot"
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      return {
        ...prev,
        [day]: [
          ...daySlots,
          { startTime: "", endTime: "", ampmStart: "", ampmEnd: "" },
        ],
      };
    });
  };

  // const saveTimeSlot = (email) => {
  //   const selectedDays = weekDays.filter((_, index) => localCheckedDays[index]);

  //   const hasOverlap = (slots) => {
  //     return slots.some((slot, index) =>
  //       doesOverlap(
  //         slot,
  //         slots.filter((_, i) => i !== index)
  //       )
  //     );
  //   };
  //   const hasAtLeastOneTimeslot = selectedDays.some(
  //     (day) => localTimeslots[day] && localTimeslots[day].length > 0
  //   );
  //   const hasInvalidTimeslot = (slots) => {
  //     return slots.some((slot) => slot.startTime === slot.endTime);
  //   };

  //   const emptyDays = selectedDays.filter(
  //     (day) => !localTimeslots[day] || localTimeslots[day].length === 0
  //   );

  //   const overlappingDays = selectedDays.filter(
  //     (day) => localTimeslots[day] && hasOverlap(localTimeslots[day])
  //   );

  //   const invalidTimeslotDays = selectedDays.filter(
  //     (day) => localTimeslots[day] && hasInvalidTimeslot(localTimeslots[day])
  //   );

  //   const incompleteDays = selectedDays.filter(
  //     (day) => localTimeslots[day] && !isLastSlotFilled(localTimeslots[day])
  //   );

  //   // if (!hasAtLeastOneTimeslot) {
  //   //   setError("Please Select At Least One Timeslot.");
  //   //   setTimeout(() => setError(""), 4000);
  //   // }
  //   if (emptyDays.length > 0) {
  //     setError(`Please Select Timeslots For: ${emptyDays.join(", ")}`);
  //     setTimeout(() => setError(""), 4000);
  //   } else if (overlappingDays.length > 0) {
  //     setError(
  //       `There Are Overlapping Timeslots For: ${overlappingDays.join(", ")}`
  //     );
  //     setTimeout(() => setError(""), 4000);
  //   } else if (incompleteDays.length > 0) {
  //     setError("Please Fill Out The Timeslots Before Saving.");
  //     setTimeout(() => setError(""), 4000);
  //   } else if (invalidTimeslotDays.length > 0) {
  //     setError(
  //       `Invalid Timeslots Found In: ${invalidTimeslotDays.join(
  //         ", "
  //       )} (Start Time Cannot Be The Same As End Time)`
  //     );
  //     setTimeout(() => setError(""), 4000);
  //   } else {
  //     setSelectedProvider("");
  //     setIsSlotModelOpen(false);

  //     const filteredTimeslots = {};
  //     setTimeslots(localTimeslots);
  //     setCheckedDays(localCheckedDays);
  //     for (const [day, slots] of Object.entries(timeslots)) {
  //       if (slots.length > 0) {
  //         filteredTimeslots[day] = slots;
  //       }
  //     }

  //     setProviderTimeSlots((prev) => ({
  //       ...prev,
  //       [email]: {
  //         timeslots: filteredTimeslots,
  //         activeStatus: isActive,
  //       },
  //     }));
  //   }
  // };

  const saveTimeSlot = (email) => {
    const selectedDays = weekDays.filter((_, index) => localCheckedDays[index]);

    const hasOverlap = (slots) => {
      return slots.some((slot, index) =>
        doesOverlap(
          slot,
          slots.filter((_, i) => i !== index)
        )
      );
    };

    const hasAtLeastOneTimeslot = selectedDays.some(
      (day) => localTimeslots[day] && localTimeslots[day].length > 0
    );

    function convertTimeToMinutes(time, period) {
      let [hour, minute] = time.split(":").map(Number);

      // Convert to 24-hour format
      if (period.toUpperCase() === "PM" && hour !== 12) {
        hour += 12;
      } else if (period.toUpperCase() === "AM" && hour === 12) {
        hour = 0;
      }

      return hour * 60 + minute;
    }

    const cutoffTime = convertTimeToMinutes("8:00", "PM");

    const hasTimeslotAfterCutoff = (slots) => {
      return slots.some((slot) => {
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);
        return endTime > cutoffTime;
      });
    };

    const timeslotAfterCutoffDays = selectedDays.filter(
      (day) =>
        localTimeslots[day] && hasTimeslotAfterCutoff(localTimeslots[day])
    );
    const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
    const hasTimeslotBeforeCutoff = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        return startTime < cutoffTimeStart;
      });
    };

    const timeslotBeforeCutoffDays = selectedDays.filter(
      (day) =>
        localTimeslots[day] && hasTimeslotBeforeCutoff(localTimeslots[day])
    );

    const hasOvernightSlot = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        // if (startTime === endTime) {
        //   return true;
        // }

        if (endTime < startTime) {
          return true;
        }

        return false;
      });
    };
    const hasSameSlot = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        if (startTime === endTime) {
          return true;
        }

        return false;
      });
    };
    const emptyDays = selectedDays.filter(
      (day) => !localTimeslots[day] || localTimeslots[day].length === 0
    );

    const overlappingDays = selectedDays.filter(
      (day) => localTimeslots[day] && hasOverlap(localTimeslots[day])
    );

    // const invalidTimeslotDays = selectedDays.filter(
    //   (day) => localTimeslots[day] && hasInvalidTimeslot(localTimeslots[day])
    // );

    const overnightSlotDays = selectedDays.filter(
      (day) => localTimeslots[day] && hasOvernightSlot(localTimeslots[day])
    );
    const sameSlots = selectedDays.filter(
      (day) => localTimeslots[day] && hasSameSlot(localTimeslots[day])
    );
    console.log(overnightSlotDays);

    const incompleteDays = selectedDays.filter(
      (day) => localTimeslots[day] && !isLastSlotFilled(localTimeslots[day])
    );

    if (emptyDays.length > 0) {
      setError(`Please Select Timeslots For: ${emptyDays.join(", ")}`);
      setTimeout(() => setError(""), 4000);
    } else if (timeslotAfterCutoffDays.length > 0) {
      setError(
        `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotAfterCutoffDays.join(
          ", "
        )}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (timeslotBeforeCutoffDays.length > 0) {
      setError(
        `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotBeforeCutoffDays.join(
          ", "
        )}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (overlappingDays.length > 0) {
      setError(
        `There Are Overlapping Timeslots For: ${overlappingDays.join(", ")}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (incompleteDays.length > 0) {
      setError("Please Fill Out The Timeslots Before Saving.");
      setTimeout(() => setError(""), 4000);
      // } else if (invalidTimeslotDays.length > 0) {
      //   setError(
      //     `Invalid Timeslots Found In: ${invalidTimeslotDays.join(
      //       ", "
      //     )} (Start Time Cannot Be The Same As End Time)`
      //   );
      setTimeout(() => setError(""), 4000);
    } else if (sameSlots.length > 0) {
      setError(
        `Start Time And End Time Cannot Be Same In: ${sameSlots.join(", ")}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (overnightSlotDays.length > 0) {
      setError(
        `Overnight Timeslots Found In: ${overnightSlotDays.join(
          ", "
        )} ((Start Time Cannot Be Greated Than End Time))`
      );
      setTimeout(() => setError(""), 4000);
    } else {
      setSelectedProvider("");
      setIsSlotModelOpen(false);

      const filteredTimeslots = {};
      setTimeslots(localTimeslots);
      setCheckedDays(localCheckedDays);
      for (const [day, slots] of Object.entries(timeslots)) {
        if (slots.length > 0) {
          filteredTimeslots[day] = slots;
        }
      }

      setProviderTimeSlots((prev) => ({
        ...prev,
        [email]: {
          timeslots: filteredTimeslots,
          activeStatus: isActive,
        },
      }));
    }
  };

  const handleDeleteSlot = (day, idx) => {
    setLocalTimeslots((prev) => {
      const updatedDaySlots = prev[day].filter((_, i) => {
        return i !== idx;
      });

      return {
        ...prev,
        [day]: updatedDaySlots,
      };
    });
  };
  const isLastSlotFilled = (slots) => {
    return slots.every(
      (slot) => slot.startTime && slot.endTime && slot.ampmStart && slot.ampmEnd
    );
  };

  const handleSlotModal = (email) => {
    setSelectedProvider(email);
    setIsSlotModelOpen(true);
    setLocalTimeslots(timeslots);
    setLocalCheckedDays(checkedDays);
  };

  const handleSelectChange = (day, idx, field, value) => {
    setLocalTimeslots((prev) => ({
      ...prev,
      [day]: prev[day].map((slot, i) =>
        i === idx ? { ...slot, [field]: value } : slot
      ),
    }));
  };
  // const toggleStatus = () => {
  //   setIsActive(!isActive);
  //   setProviderTimeSlots((prev) => ({
  //     ...prev,
  //     [email]: {
  //       ...prev[email],
  //       activeStatus: !isActive,
  //     },
  //   }));
  // };

  const toggleStatus = async () => {
    const newIsActive = !isActive;

    if (!newIsActive) {
      const result = await Swal.fire({
        title: "Are You Sure?",
        text: "Only Providers Who Are 'Active' Will Be Visible For Scheduling.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change It!",
        cancelButtonText: "No, Cancel!",
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    setIsActive(newIsActive);
    setProviderTimeSlots((prev) => ({
      ...prev,
      [email]: {
        ...prev[email],
        activeStatus: newIsActive,
      },
    }));
  };

  return (
    <div className="flex justify-between">
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className="w-2/6 m-2 py-2 cursor-pointer"
      >
        <img
          src={src || "/placeholder.png"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/placeholder.png";
          }}
          className="rounded-full mx-auto w-10 h-10"
          alt={name}
        />
        <div className="w-full font-fontPoppins text-xs font-semibold text-center">
          {name}
        </div>
      </div>

      <div className="pt-2 pl-2 my-1 bg-white w-4/12 rounded">
        {Object.entries(timeslots).some(
          ([day, slots]) =>
            checkedDays[weekDays.indexOf(day)] && slots.length > 0
        ) ? (
          Object.entries(timeslots)
            .sort((a, b) => weekDays.indexOf(a[0]) - weekDays.indexOf(b[0]))
            .map(([day, slots]) =>
              checkedDays[weekDays.indexOf(day)]
                ? slots.map((slot, idx) => (
                    <div key={idx} className="flex justify-between items-start">
                      <div className="">{day.slice(0, 3)}:</div>
                      <div className="w-3/4">
                        {slot.startTime} {slot.ampmStart}-{slot.endTime}{" "}
                        {slot.ampmEnd}
                      </div>
                    </div>
                  ))
                : null
            )
        ) : (
          <div
            onClick={() => handleSlotModal(email)}
            className="text-blue-500 text-base font-bold cursor-pointer"
          >
            Add
          </div>
        )}

        {Object.entries(timeslots).some(
          ([day, slots]) =>
            checkedDays[weekDays.indexOf(day)] && slots.length > 0
        ) && (
          <div
            onClick={() => handleSlotModal(email)}
            className="text-blue-500 text-base font-bold cursor-pointer"
          >
            Edit
          </div>
        )}
      </div>

      <div>
        <div className="p-2 m-6 mr-5 h-10 bg-white rounded w-24">
          <div onClick={toggleStatus}>
            {isActive ? (
              <div className="flex items-center text-green-500 font-bold cursor-pointer">
                <div
                  className="w-3 h-3 bg-green-500 rounded-full mr-2 "
                  style={{ marginTop: "1px" }}
                />
                <span>Active</span>
              </div>
            ) : (
              <div className="flex items-center text-red-500 font-bold cursor-pointer">
                <div
                  className="w-3 h-3 bg-red-500 rounded-full mr-2 "
                  style={{ marginTop: "1px" }}
                />
                <span>InActive</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {isSlotModalOpen && selectedProvider === email && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-85 backdrop-filter backdrop-blur-sm flex justify-center items-center">
          <div className="bg-white rounded-lg md:w-3/5 shadow-lg border border-gray-200 h-2/3  sm:w-3/4 overflow-x-auto overflow-y-auto">
            {/* <div className="bg-white rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/3 md:h-2/3 sm:h-2/3 lg:h-4/5 xl:h-5/6 shadow-lg border border-gray-200 overflow-auto"> */}
            <div className="sticky top-0 bg-white border-b border-gray-300 z-10">
              <div className="flex p-2 px-4 justify-center items-center">
                <p className="text-xl sm:text-xl font-semibold">
                  Working Hours
                </p>
              </div>
            </div>
            <div>
              {weekDays.map((day, index) => (
                <div key={index} className="flex p-2  px-4">
                  <div className="flex items-start mr-3 m-3 w-32 max-h-auto">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 mr-2 mt-1"
                      checked={localCheckedDays[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <span>{day}</span>
                  </div>

                  {localCheckedDays[index] && (
                    <div className="w-full flex flex-col justify-center">
                      <div className="w-full flex flex-col ">
                        {(localTimeslots[day] || []).map((slot, idx) => (
                          <div
                            key={idx}
                            className="flex max-1000:flex-col items-center max-1000:space-y-2 space-x-2 mt-2"
                          >
                            <CustomSelect
                              placeholder="Start Time"
                              name="Start Time"
                              className="border rounded m-3 p-1 text-gray-700 "
                              value={slot.startTime}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              options={timeOptions.map((time, optionIdx) => ({
                                value: time.time,
                                label: time.label,
                              }))}
                            />
                            {/* { => (
                                <option key={optionIdx} value={time}>
                                  {time}
                                </option>
                              ))} */}
                            {/* </select> */}
                            <CustomSelect
                              className="border rounded p-1 text-gray-700"
                              placeholder="AM/PM"
                              name="AM/PM"
                              value={slot.ampmStart}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "ampmStart",
                                  e.target.value
                                )
                              }
                              options={[
                                { value: "AM", label: "AM" },
                                { value: "PM", label: "PM" },
                              ]}
                            />

                            <span className="text-gray-700">to</span>

                            <CustomSelect
                              placeholder="End Time"
                              className="border rounded p-1 text-gray-700 w-10 "
                              name="End Time"
                              value={slot.endTime}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "endTime",
                                  e.target.value
                                )
                              }
                              options={timeOptions.map((time, optionIdx) => ({
                                value: time.time,
                                label: time.label,
                              }))}
                            />
                            <CustomSelect
                              className="border rounded p-1 text-gray-700"
                              value={slot.ampmEnd}
                              placeholder="AM/PM"
                              name="AM/PM"
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "ampmEnd",
                                  e.target.value
                                )
                              }
                              options={[
                                { value: "AM", label: "AM" },
                                { value: "PM", label: "PM" },
                              ]}
                            />

                            <span className="ml-3 cursor-pointer ">
                              <i
                                onClick={() => handleDeleteSlot(day, idx)}
                                className="fa-solid fa-close"
                              ></i>
                            </span>
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={() => addTimeslot(day)}
                        className=" w-1/6 mt-3 p-1 ml-2 bg-gray-500 text-white rounded "
                        title="Add Timeslot"
                      >
                        <i className="fa-solid fa-plus text-xl"></i>
                      </button>

                      {timeslotError && (
                        <p className="text-red-500 text-sm mt-2 ">
                          {timeslotError}
                        </p>
                      )}
                    </div>
                  )}

                  {!localCheckedDays[index] && (
                    <div className="text-gray-500 text-sm mt-3">
                      Unavailable
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="sticky bottom-0 border-0 bg-white border-t border-gray-300  ">
              <div className=" h-20 flex justify-center items-center relative">
                <div className="flex flex-col items-center">
                  <div className="flex justify-between items-center ">
                    <button
                      onClick={handleSlotModal}
                      className="ml-2 p-2 bg-blue-500 text-white rounded "
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => saveTimeSlot(email)}
                      className="ml-2 p-2 bg-blue-500 text-white rounded "
                    >
                      Submit
                    </button>
                  </div>
                  {error && (
                    <p className="text-red-500 text-base font-semibold absolute mt-9 ">
                      {error}
                    </p>
                  )}
                  {saveError && (
                    <p className="text-green-500 text-base font-semibold  absolute mt-9">
                      {saveError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ServiceRow = ({ service, onDropProvider, onRemoveProvider }) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.PROVIDER,
      drop: (item) => onDropProvider(service, item),
      canDrop: (item) =>
        !service.providers.some((provider) => provider.name === item.name),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [service.providers]
  );

  const removeItem = (provider) => {
    onRemoveProvider(service, provider);
  };

  return (
    <div
      ref={drop}
      className={`w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 pr-0 flex items-center gap-3 xss:overflow-auto`}
    >
      <button className="font-fontPoppins  text-xs font-medium bg-white shadow-sm rounded-full w-[120px]  py-3">
        {service?.name}
      </button>
      <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full  min-w-[40px] p-2">
        {service?.duration}
      </button>
      <span className="text-gray-400">
        <i className="fa-solid fa-play fa-lg"></i>
      </span>
      <div className=" gap-3 flex w-[400px] overflow-x-auto">
        {service &&
        service.providers &&
        Array.isArray(service.providers) &&
        service.providers.length > 0 ? (
          service.providers.map((provider, index) => (
            <div
              key={index}
              className="w-auto p-0 m-0 flex flex-col items-center justify-center"
            >
              <img
                src={provider?.src || "/placeholder.png"}
                className="rounded-full mx-auto w-10 h-10"
                alt={provider?.name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/placeholder.png";
                }}
              />
              <div className="font-fontPoppins text-xs  font-semibold text-center">
                {provider?.name}
              </div>

              <span
                onClick={() => removeItem(provider)}
                className="cursor-pointer"
              >
                <i className="  fa-regular fa-circle-xmark"></i>
              </span>
            </div>
          ))
        ) : (
          <div className="text-gray-400 text-sm">No Providers Added</div>
        )}
      </div>
    </div>
  );
};

function ServiceToProviderMapping() {
  const [services, setServices] = useState([]);
  const [clinicServicesData, setClinicServicesData] = useState([]);
  const [clinicServicesMapping, setClinicServicesMapping] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewService, setAddnewService] = useState("");
  const [isAddNewService, setIsAddnewService] = useState(false);
  const [error, setError] = useState("");
  const [errorInvalid, setErrorInvalid] = useState("");
  const [addNewDuration, setAddNewDuration] = useState("");
  const location = useLocation();
  const { organizationData, providerData, addLocations, providerisChecked } =
    location.state || {};
  const [organizationId, setOrganizationId] = useState("");
  const [OrganizationData, setOrganizationData] = useState({});
  const [addLocation, setAddLocation] = useState([]);
  const [providerisCheck, setProviderisCheck] = useState();
  const [ProviderData, setProviderData] = useState({});
  const [isSlotModalOpen, setIsSlotModelOpen] = useState(false);
  const [providerTimeslots, setProviderTimeSlots] = useState({});
  const [loading, setLoading] = useState(false);
  const [mappingisChecked, setMappingisChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            setOrganizationId(apiResponse.data.id);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );

            const {
              clinic_services_data,
              clinic_services_mapping,
              providerisChecked,
              practitioners_data,
              clinics_data,
              providerTimeslots,
              clinic_locations,
              mappingisChecked,
            } = parsedPayloadData;
            setProviderList(practitioners_data || []);
            setClinicServicesData(clinic_services_data || []);
            setClinicServicesMapping(clinic_services_mapping || []);
            setOrganizationData(clinics_data || []);
            setAddLocation(clinic_locations || []);
            setProviderData(practitioners_data || []);
            setProviderisCheck(providerisChecked || "");
            setMappingisChecked(mappingisChecked);
            setProviderTimeSlots(
              convertToOriginalFormat(providerTimeslots) || {}
            );

            const mergedData = clinic_services_data.map((service) => {
              const providers = clinic_services_mapping
                .filter((mapping) => mapping.services === service.services)
                .map((mapping) => ({
                  name: mapping.name,
                  email: mapping.email,
                }));
              return {
                name: service.services,
                duration: service.duration,
                providers: providers,
              };
            });

            setServices(mergedData || []);
          } else {
            console.error("No data found for organizationId:", organizationId);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const convertToOriginalFormat = (data) => {
    const result = {};

    data.practitioner_working_hours_details.forEach((practitioner) => {
      const timeslots = {};

      practitioner.practitioner_working_hours.forEach((slot) => {
        if (slot.is_available) {
          if (!timeslots[slot.day]) {
            timeslots[slot.day] = [];
          }
          timeslots[slot.day].push({
            startTime: convertTo12HourFormat(slot.from_time),
            endTime: convertTo12HourFormat(slot.to_time),
            ampmStart: getAMPM(slot.from_time),
            ampmEnd: getAMPM(slot.to_time),
          });
        }
      });

      result[practitioner.email] = {
        timeslots,
        activeStatus: practitioner.is_active_today ? true : false,
      };
    });

    return result;
  };

  const convertTo12HourFormat = (time) => {
    let [hours, minutes, seconds] = time.split(":");
    hours = parseInt(hours);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes}`;
  };

  const getAMPM = (time) => {
    const [hours] = time.split(":");
    return parseInt(hours) >= 12 ? "PM" : "AM";
  };

  useEffect(() => {
    if (
      (providerList && providerList.length === 0 && services.length > 0) ||
      (providerList && providerList.length > 0 && services.length > 0)
    ) {
      updateProvidersInServices(providerList);
    }
  }, [providerList]);

  const updateProvidersInServices = (updatedProviderList) => {
    if (!updatedProviderList || updatedProviderList.length === 0) {
      const emptyServices = services.map((service) => ({
        ...service,
        providers: [],
      }));
      setServices(emptyServices);

      setClinicServicesMapping([]);

      return;
    }

    const updatedServices = services.map((service) => {
      const filteredProviders = service.providers.filter((provider) =>
        updatedProviderList.some((p) => p.email === provider.email)
      );

      const providersToRemove = service.providers.filter(
        (provider) =>
          !updatedProviderList.some((p) => p.email === provider.email)
      );

      if (providersToRemove.length > 0) {
        console.log("Removing providers from service row:", providersToRemove);
      }

      return {
        ...service,
        providers: filteredProviders.map((provider) => {
          const updatedProvider = updatedProviderList.find(
            (p) => p.email === provider.email
          );
          return updatedProvider
            ? {
                ...provider,
                name: `${updatedProvider.f_name} ${updatedProvider.l_name}`,
                src: updatedProvider.photo,
              }
            : provider;
        }),
      };
    });

    const updatedMappings = clinicServicesMapping
      .map((mapping) => {
        const updatedProvider = updatedProviderList.find(
          (p) => p.email === mapping.email
        );
        if (updatedProvider) {
          return {
            ...mapping,
            name: `${updatedProvider.f_name} ${updatedProvider.l_name}`,
            src: updatedProvider.photo,
          };
        }
        return mapping;
      })
      .filter((mapping) =>
        updatedProviderList.some((p) => p.email === mapping.email)
      );

    setServices(updatedServices);

    setClinicServicesMapping(updatedMappings);
  };

  const handleDropProvider = (service, item) => {
    setServices((prevServices) => {
      const updatedServices = prevServices.map((s) =>
        s.name === service.name
          ? {
              ...s,
              providers: [
                ...s.providers,
                {
                  name: item.name,
                  services: service.name,
                  src: item.src,
                  email: item.email,
                  is_active: 1,
                },
              ],
            }
          : s
      );

      return updatedServices;
    });
    setClinicServicesMapping((prevMappings) => {
      const updatedMappings = [
        ...prevMappings,
        {
          services: service.name,
          name: item.name,
          email: item.email,
          duration: service.duration,
          is_active: 1,
        },
      ];
      return updatedMappings;
    });
  };

  const handleRemoveProvider = (service, provider) => {
    setServices((prevServices) => {
      const updatedServices = prevServices.map((s) =>
        s.name === service.name
          ? {
              ...s,
              providers: s.providers.filter((p) => p.name !== provider.name),
            }
          : s
      );

      return updatedServices;
    });
    setClinicServicesMapping((prevMappings) => {
      const updatedMappings = prevMappings.filter(
        (mapping) =>
          !(
            mapping.services === service.name &&
            mapping.email === provider.email &&
            mapping.name === provider.name
          )
      );

      return updatedMappings;
    });
  };

  const handleModal = () => {
    setIsModalOpen(false);
    setIsAddnewService(false);
  };

  const HandleAddNewService = () => {
    setIsAddnewService(true);
  };

  const handleAddService = (e) => {
    if (addNewService.trim() && addNewDuration.trim()) {
      const serviceExists = services.some(
        (service) =>
          service.name.toLowerCase() === addNewService.trim().toLowerCase()
      );

      if (serviceExists) {
        setError("Service Already Exists");
        setTimeout(() => setError(""), 2000);
        return;
      }
      const newServices = [
        ...services,
        {
          name: addNewService.trim(),
          duration: addNewDuration.trim(),
          providers: [],
        },
      ];
      setServices(newServices);

      const newClinicService = {
        services: addNewService.trim(),
        duration: addNewDuration.trim(),
        is_active: 1,
      };

      const newClinicServicesData = Array.isArray(clinicServicesData)
        ? [...clinicServicesData, newClinicService]
        : [newClinicService];
      setClinicServicesData(newClinicServicesData);

      setAddnewService("");
      setAddNewDuration("");
      setIsAddnewService(false);
    } else {
      setError("Please Enter Both Appointment Type And Duration");
      setTimeout(() => setError(""), 2000);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const onlyNumbersRegex = /^\d+$/;
      const onlySpecialCharsRegex = /^[!@#$%^&*(),.?":{}|<>0-9]+$/;

      if (onlyNumbersRegex.test(value) || onlySpecialCharsRegex.test(value)) {
        setErrorInvalid("Invalid Appointment Type");
        setAddnewService("");
      } else {
        setAddnewService(value);
        setErrorInvalid("");
      }
    } else if (name === "duration") {
      const onlyNumbersRegex = /^\d+$/;

      if (onlyNumbersRegex.test(value)) {
        setAddNewDuration(value);
        setErrorInvalid("");
      } else {
        setErrorInvalid("Invalid Duration");
        setAddNewDuration("");
      }
    }
  };

  const handleDeleteService = (serviceName) => {
    const filteredServices = services.filter(
      (service) => service.name !== serviceName
    );
    setServices(filteredServices);

    const filteredClinicServicesData = clinicServicesData.filter(
      (service) => service.services !== serviceName
    );
    setClinicServicesData(filteredClinicServicesData);

    const filteredClinicServicesMapping = clinicServicesMapping.filter(
      (mapping) => mapping.services !== serviceName
    );
    setClinicServicesMapping(filteredClinicServicesMapping);

    setAddnewService("");
    setErrorInvalid("");
  };
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const convertTo24HourFormat = (time, period) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
  };

  const convertToDesiredFormat = (data) => {
    const result = { practitioner_working_hours_details: [] };
    // console.log(data);
    for (const email in data) {
      if (data.hasOwnProperty(email)) {
        const practitioner = {
          email: email,
          is_active_today: data[email].activeStatus === false ? 0 : 1,
          practitioner_working_hours: [],
        };

        daysOfWeek.forEach((day) => {
          if (
            data[email]?.timeslots &&
            data[email]?.timeslots[day] &&
            data[email]?.timeslots[day].length > 0
          ) {
            data[email].timeslots[day].forEach((slot) => {
              const fromTime = convertTo24HourFormat(
                slot.startTime,
                slot.ampmStart
              );
              const toTime = convertTo24HourFormat(slot.endTime, slot.ampmEnd);
              practitioner.practitioner_working_hours.push({
                day: day,
                from_time: fromTime,
                to_time: toTime,
                is_available: 1,
                is_active: 1,
              });
            });
          } else {
            practitioner.practitioner_working_hours.push({
              day: day,
              is_available: 0,
              is_active: 1,
            });
          }
        });

        result.practitioner_working_hours_details.push(practitioner);
      }
    }

    return result;
  };

  const handleNavigate = async () => {
    if (!mappingisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    if (clinicServicesMapping.length > 0 && clinicServicesData.length > 0) {
      setError("");
      try {
        setLoading(true);
        const organizationId = localStorage.getItem("organizationId");
        let payload;

        if (organizationId) {
          const fetchPayload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData(
            "masters/getMasterList",
            fetchPayload
          );

          let existingData = {};
          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            existingData = JSON.parse(apiResponse.data[0].payload_data);
          }

          const isFirstTime =
            !existingData.clinic_services_data &&
            !existingData.clinic_services_mapping &&
            !existingData.mappingisChecked &&
            !existingData.providerTimeslots;

          if (isFirstTime) {
            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify({
                  clinics_data: organizationData,
                  providerisChecked: providerisChecked,
                  practitioners_data: providerData,
                  clinic_locations: addLocations,
                  clinic_services_data: clinicServicesData,
                  clinic_services_mapping: clinicServicesMapping,
                  mappingisChecked: mappingisChecked,

                  providerTimeslots: convertToDesiredFormat(providerTimeslots),
                }),
              },
            };
            // console.log("parse", updatedData);
          } else {
            const updatedData = {
              ...existingData,
              clinics_data: existingData.clinics_data || organizationData,
              providerisChecked:
                existingData.providerisChecked || providerisChecked,
              practitioners_data:
                existingData.practitioners_data || providerData,
              clinic_locations: existingData.clinic_locations || addLocations,
              clinic_services_data: clinicServicesData,
              clinic_services_mapping: clinicServicesMapping,
              mappingisChecked: mappingisChecked,
              providerTimeslots: convertToDesiredFormat(providerTimeslots),
            };

            payload = {
              modelName: "subscription_temp",
              id: organizationId,
              inputData: {
                payload_data: JSON.stringify(updatedData),
              },
            };
          }
        }

        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );
        setLoading(false);

        navigate("/frontDeskAssistant", {
          state: {
            organizationData,
            providerData,
            clinicServicesMapping,
            clinicServicesData,
            providerTimeslots: convertToDesiredFormat(providerTimeslots),
            addLocations,
            providerisChecked,
            mappingisChecked,
          },
        });
      } catch (error) {
        setLoading(false);
        console.error("Error saving data:", error);
        setError("Failed to save data. Please try again.");
        setTimeout(() => setError(""), 3000);
      }
    } else {
      setLoading(false);
      setError("Please Add Providers To Appointment Type");
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      {/* ) : ( */}
      <>
        <Header />
        <div className="pt-16">
          <div className="container mx-auto px-4 flex items-center justify-between gap-16 xss:gap-2 sm:gap-5 relative">
            <StepsForRegistration />
            <h1 className="font-fontPoppins md:text-xl font-semibold text-xs xss:text-xs sm:text-sm leading-normal absolute left-1/2  sm:left-1/2 sm:transform sm:-translate-x-1/2 xss:right-0">
              Appointment To Provider Mapping
            </h1>
          </div>
          <DndProvider backend={HTML5Backend}>
            <section className="w-full">
              <div className="container mx-auto px-4">
                {/* <h2 className="font-fontPoppins text-gray-700 text-xs font-bold md:pl-96 xss:text-center sm:text-center bg-gray-100  rounded shadow-md">
              Drag The Provider Icon To The Appointment Type-Row To Map
            </h2> */}
                <h2 className="font-fontPoppins text-lg font-bold text-center text-gray-700 bg-gray-100  rounded shadow-md">
                  Drag The Provider Icon To The Appointment Type-Row To Map
                </h2>

                <div className="w-full mt-4 md:flex md:justify-center md:gap-4">
                  <div className="md:w-4/5 xss:w-full tiny:w-full xss-w-full sm:w-full">
                    <div className="font-fontPoppins text-sm font-semibold px-3  pl-17">
                      Add Appointment Type{" "}
                      {/* <i
                    onClick={() => setIsModalOpen(true)}
                    className="fa-solid fa-gear fa-xs text-gray-400"
                  ></i> */}
                      <span class="rounded- ml-2  p-1 bg-gray-100 cursor-pointer shadow-md transition duration-100 ">
                        <i
                          onClick={() => setIsModalOpen(true)}
                          class="fa-solid fa-plus fa-lg text-gray-900 hover:text-gray-1000"
                          title="Click here to add Appointment services"
                        ></i>
                      </span>
                    </div>
                    <div className="w-full min-h-96 bg-grayColorLight rounded-lg mt-3 max-h-96 overflow-y-auto">
                      <div className="w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto">
                        <div className="  text-l text-center min-w-[120px] text-center  font-semibold">
                          Appointment <br /> Type
                        </div>
                        <div className="text-l text-center min-w-[40px]  font-semibold">
                          Duration
                        </div>
                        <div className=" text-l w-[330px] text-center font-semibold">
                          Providers
                        </div>
                      </div>
                      {services &&
                        services.map(
                          (service, index) =>
                            service && (
                              <div
                              //  className="w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto"
                              >
                                <ServiceRow
                                  key={index}
                                  service={service}
                                  onDropProvider={handleDropProvider}
                                  onRemoveProvider={handleRemoveProvider}
                                />
                              </div>
                            )
                        )}
                    </div>
                  </div>

                  <div className="md:w-1/5 xss:w-full tiny:w-full sm:w-full ">
                    <div className="font-fontPoppins text-sm font-semibold px-3 text-center">
                      Providers
                    </div>
                    {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg p-3 mt-1"> */}
                    <div className="w-full min-h-96 bg-grayColorLight rounded-lg mt-3 max-h-96 overflow-y-auto">
                      <div className="w-full border-b py-4 border-solid border-gray-200 flex justify-between  items-center ">
                        <div className="text-l w-2/6  text-center py-2 font-semibold">
                          Providers
                        </div>
                        <div
                          // className="  text-l pt-2 pl-8 my-1 w-4/12 text-center font-semibold"

                          className=" text-l  my-1  text-center w-4/12 font-semibold"
                          // style={{ paddingLeft: "60px" }}
                        >
                          Working Hours
                        </div>
                        <div
                          // className=" text-l text-center rounded w-24  font-semibold"
                          className="p-2 ml-6 mr-5  font-semibold w-24 text-center"
                        >
                          Status
                        </div>
                      </div>
                      {providerList &&
                        providerList.map((provider, index) => (
                          <Provider
                            key={index}
                            name={`${provider.f_name} ${provider.l_name}`}
                            src={provider.photo}
                            email={provider.email}
                            isSlotModalOpen={isSlotModalOpen}
                            setIsSlotModelOpen={setIsSlotModelOpen}
                            providerTimeslots={providerTimeslots}
                            setProviderTimeSlots={setProviderTimeSlots}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="flex  mt-4 items-center gap-2">
                  <input
                    type="checkbox"
                    checked={mappingisChecked}
                    onChange={(e) => setMappingisChecked(e.target.checked)}
                  />

                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500  text-xs absolute">
                    {errorMessage}
                  </p>
                )}
              </div>
            </section>
            {isModalOpen && (
              <div className="fixed inset-0  z-50 overflow-y-auto bg-gray-800 bg-opacity-75 backdrop-filter backdrop-blur-sm flex justify-center items-center">
                <div className="bg-white rounded-lg  w-1/2 shadow-lg border border-gray-200">
                  <div className="flex p-2 px-4  justify-between items-center border-b border-gray-300 ">
                    <p className="text-xl sm:text-xl font-semibold ">
                      Appointment Type
                    </p>
                    <button
                      onClick={HandleAddNewService}
                      className="text-blue-700  border border-blue-700 bg-transparent px-2 p-1 hover:text-blue-400 rounded-full focus:outline-none"
                    >
                      Add New Appointment Type
                    </button>
                  </div>
                  <div className="p-2 px-4 flex justify-between border-b border-gray-200 font-semibold">
                    <p className="w-1/3 text-center text-l sm:text-sm">
                      Appointment Type
                    </p>
                    <p className=" text-center text-l sm:text-sm">
                      Duration In Minutes
                    </p>
                    <p className="w-1/3 text-center text-l sm:text-sm">
                      Action
                    </p>
                  </div>
                  {services &&
                    services.map((service, index) => (
                      <div
                        key={index}
                        className="flex p-2 px-4 justify-between items-center border-b border-gray-200"
                      >
                        <p className="w-1/3 text-center text-s sm:text-sm">
                          {service?.name}
                        </p>
                        <p className="w-1/4 text-center text-s sm:text-sm">
                          {service?.duration}
                        </p>
                        <div className="w-1/3 flex justify-center">
                          <span
                            onClick={() => handleDeleteService(service.name)}
                            className="text-gray-400 cursor-pointer hover:text-red-500 text-s sm:text-sm"
                          >
                            <i className="fa-solid fa-trash"></i> Delete
                          </span>
                        </div>
                      </div>
                    ))}
                  {isAddNewService && (
                    <>
                      <div className="p-2 px-4 flex justify-between border-b border-gray-200">
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Appointment Type"
                          className="border border-black rounded-lg py-2 px-3 mb-3 mr-4 w-full shadow-sm"
                          value={addNewService}
                          onChange={handleChange}
                        />

                        <input
                          type="text"
                          name="duration"
                          placeholder="Enter Duration In Minutes"
                          className="border border-black rounded-lg py-2 px-3 mb-3 w-full shadow-sm"
                          value={addNewDuration}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="text-red-500 ml-2">{error}</div>
                      <div className="flex justify-center items-center mt-4">
                        <span
                          onClick={handleAddService}
                          className="text-black mr-3 cursor-pointer hover:text-blue-500 text-l sm:text-sm"
                        >
                          <i className="fas fa-add"></i> Add
                        </span>
                        <span
                          onClick={() => {
                            setIsAddnewService(false);
                            setErrorInvalid("");
                            setAddnewService("");
                            setAddNewDuration("");
                          }}
                          className="text-black mr-2 cursor-pointer hover:text-red-500 text-l sm:text-sm"
                        >
                          <i className="fa-solid fa-trash"></i> Cancel
                        </span>
                      </div>
                    </>
                  )}

                  {/* <div className="text-red-500 ml-2">{error}</div> */}
                  <div className="flex justify-center pb-2 ">
                    <span
                      onClick={handleModal}
                      className="mr-2 cursor-pointer pt-14 "
                    >
                      <i className="fa-solid fa-close"></i> Close
                    </span>
                  </div>
                </div>
              </div>
            )}
          </DndProvider>
          <section className="w-full mt-10 xss:mt-5 sm:mt-5 ">
            <div className="container mx-auto px-4 text-center">
              <button
                onClick={handleNavigate}
                className="w-56 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <span className="me-2">Add Front Office Team</span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </button>
              {<p className="text-red-500 ml-2">{error}</p>}
            </div>
          </section>

          <Footer />
        </div>
      </>
      {/* )} */}
    </>
  );
}

export default ServiceToProviderMapping;
