import React from "react";
import Select from "react-select";

const CustomSelectHealth = ({
  options,
  value,
  onChange,
  placeholder,
  name,
}) => {
  const getDefaultLabel = () => {
    switch (name) {
      case "selectedPlan":
        return "Select All Insurance Plans Accepted";
      case "insuranceCarrierName":
        return "Select All Insurance Carriers Accepted";
      case "selectedPlanBooking":
        return "Select Your Insurance Plan";
      case "address_ype":
        return "Select Your Address Type";
      case "state":
        return "Select State";
      case "country":
        return "Select Country";
      case "clinicLocation":
        return "Select Clinic Location";
      case "Start Time":
        return "Start Time";
      case "End Time":
        return "End Time";
      case "AM/PM":
        return "AM/PM";
      default:
        return "Select an option";
    }
  };
  const formattedOptions = [
    { value: "", label: getDefaultLabel() },
    ...options.map((option) => ({
      value: option.value,
      label: option.label,
    })),
  ];

  const selectedOption =
    formattedOptions.find((opt) => opt.value === value) || formattedOptions[0];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "40px",
      borderRadius: "9999px",
      borderColor: "#D1D5DB",
      zIndex: 20,

      "&:hover": {},
    }),
    option: (provided, state) => ({
      ...provided,

      padding: "10px",
      margin: "4px 0",
      fontFamily: "'Poppins', sans-serif",
      zIndex: 20,

      "&:hover": {
        backgroundColor: "#3B82F6",
        color: "#FFF",
      },
    }),
  };

  return (
    <Select
      value={selectedOption}
      onChange={(selectedOption) =>
        onChange({ target: { name, value: selectedOption.value } })
      }
      options={formattedOptions}
      styles={customStyles}
      className="w-full text-sm font-fontPoppins font-500  z-20"
      placeholder={placeholder}
    />
  );
};

export default CustomSelectHealth;
