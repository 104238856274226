import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function PatientsLandingPageFooter() {
  return (
    <footer className="w-full">
      <div className="w-full mx-auto py-7 h-16 bg-blueColor">
        {/* <div className="flex justify-center items-center gap-3 px-4 pb-4 border border-l-0 border-t-0 border-r-0 border-solid border-sky-500">
          <span className="font-fontPoppins text-base xss:text-sm font-semibold text-white">
            Join the newsletter
          </span>
          <input
            type="text"
            placeholder="Enter email ID"
            className="w-80 h-10 px-3 text-gray-400 text-xs rounded-full shadow-sm shadow-sky-950 focus:outline-none focus:ring focus:ring-sky-600"
          />
        </div> */}

        {/* <div className="flex items-center justify-between text-center font-fontPoppins text-xs text-white mt-12 py-5">
        <div className="flex gap-4 ml-4">
            <p>&copy; SamaraWell. All rights reserved 2024</p>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl hover:text-gray-300" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl hover:text-gray-300" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl hover:text-gray-300" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-white text-2xl hover:text-gray-300" />
            </a>
          </div>
          <div className="mr-4">
          <a href="/contact-us" className="text-white text-lg hover:text-gray-300">Contact Us</a>
      </div>
        </div> */}
        <div className="flex items-center justify-between text-center font-fontPoppins text-xs text-white mt-12 py-5">
      <div className="flex gap-4 ml-4 mx-auto"> 
        <p>&copy; SamaraWell. All rights reserved 2024</p>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl hover:text-gray-300" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl hover:text-gray-300" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl hover:text-gray-300" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="text-white text-2xl hover:text-gray-300" />
        </a>
      </div>
      <div className="mr-4">
        <Link to="/faq" className="text-white text-lg hover:text-gray-300">FAQ</Link>
      </div>
      <div className="mr-4">
        <Link to="/contactus" className="text-white text-lg hover:text-gray-300">Contact Us</Link>
      </div>
    </div>
      </div>
    </footer>
  );
}

export default PatientsLandingPageFooter;
