// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
// import success from "/success.png"
// import firebase from "../firebase/firebaseConfig";
import { Link, useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [sessionId, setSessionId] = useState("");

  // useEffect(() => {
  // window.history.forward();
  // navigate("/paymentSuccess", { replace: true });
  // });
  // }, []);
  useEffect(() => {
    navigate("/paymentSuccess", { replace: true });

    // Prevent users from navigating back
    const handlePopState = () => {
      navigate("/paymentSuccess", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handleBackNavigation = () => {
      navigate("/paymentSuccess", { replace: true });
    };

    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [navigate]);

  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     setUserId(user.uid)
    //     const userRef = firebase.database().ref("users/" + user.uid);
    //     userRef.on('value', (snapshot) => {
    //       const user = snapshot.val();
    //       if (user) {
    //         setSessionId(user.subscription.sessionId || "")
    //       }
    //     })
    //   }
    // })
  }, [userId, sessionId]);

  console.log(sessionId);

  const handlePaymentSuccess = () => {
    fetch("http://localhost:5000/api/v1/payment-success", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId: sessionId, email: userId }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then((data) => {
        console.log(data.message);
        navigate("/");
      })
      .catch((e) => {
        console.log(e.error);
      });
  };

  return (
    // <div className='m-0 p-0'>
    //   <div className='w-full min-h-[80vh] flex flex-col justify-center items-center'>
    //     <div className='my-10 text-green-600 text-2xl mx-auto flex flex-col justify-center items-center'>
    //       <img src="/success.png" alt="" width={220} height={220} />
    //       <h3 className='text-4xl pt-20 lg:pt-0 font-bold text-center text-slate-700'>
    //         Payment Successful
    //       </h3>
    //       <button onClick={() => handlePaymentSuccess()}
    //         className='w-40 uppercase bg-[#009C96] text-white text-xl my-16 px-2 py-2 rounded'
    //       >
    //         Proceed
    //       </button>
    //     </div>
    //   </div>
    // </div>
    //   <div class="bg-gray-100 h-screen">
    //     <div class="bg-white p-6  md:mx-auto">
    //       <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
    //           <path fill="currentColor"
    //               d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
    //           </path>
    //       </svg>
    //       <div class="text-center">
    //           <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
    //           <p class="text-gray-600 my-2">Thank you for completing your secure online payment.</p>
    //           <p> Have a great day!  </p>
    //           <div class="py-10 text-center">
    //               <a href="#" class="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
    //                   GO BACK
    //              </a>
    //           </div>
    //       </div>
    //   </div>
    // </div>
    <div class="bg-gray-100 min-h-screen flex items-center justify-center">
      <div class="bg-white p-6 md:mx-auto max-w-md w-full">
        <svg viewBox="0 0 24 24" class="text-green-600 w-12 h-12 mx-auto my-6">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div class="text-center">
          <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment Done!
          </h3>
          <p class="text-gray-600 my-2">
            Thank You For Completing Your Secure Online Payment.
          </p>
          <p> Have A Great Day! </p>
          <div class="py-10 text-center">
            <Link
              to="/login"
              class="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
